<template>
  <div
    class="wrapper"
    style="width: 100%;"
  >
    <menu-ver type="whistleblower" name="Whistleblower" :id_emp="emp_id" />
    <div class="container m-3">
      <h3 style="margin-top: 110pt">
        {{ $t("mwhistleblower.title") }} {{ $t("mwhistleblower.case")
        }}{{ denu_id }}
      </h3>
      <h4 style="margin-top: 16pt">
        <span v-if="caso.denu_estado === 'CREADA'"
          >{{ $t("mwhistleblower.analysis") }}
        </span>
        <span v-else-if="caso.denu_estado === 'FINALIZADA'"
          >{{ $t("mwhistleblower.finished") }}
        </span>
        <span v-else>{{ $t("mwhistleblower.analysis") }} </span>
      </h4>
<a :href="`./api/export_denu.asp?id=${denu_id}`" target=_blank>            
<i
              class="fas fa-file-export"
              :data-title="$t(`mUser.ocases.exportCase`)"
            ></i>
</a>            

      <div>
        <div
          class="card"
          id="cardForm"
          v-if="caso.denu_tipo_denuncia === 'CONSULTA'"
        >
          <div class="row form">
            <div class="col">
              <label class="form-label">{{
                $t(`forms.consultation.name`)
              }}</label>
              <input
                v-model.trim="caso.denu_nombre"
                type="text"
                class="form-control"
                id="nombre"
                :placeholder="$t(`forms.consultation.name`)"
                disabled
              />
              <label class="form-label">{{
                $t(`forms.consultation.phone`)
              }}</label>
              <input
                v-model.trim="caso.denu_telefono"
                type="phone"
                class="form-control"
                id="telefono"
                :placeholder="$t(`forms.consultation.phone`)"
                disabled
              />
            </div>
            <div class="col">
              <label class="form-label">{{
                $t(`forms.consultation.surname`)
              }}</label>
              <input
                v-model.trim="caso.denu_apellidos"
                type="text"
                class="form-control"
                id="apellido"
                :placeholder="$t(`forms.consultation.surname`)"
                disabled
              />
              <label class="form-label">{{
                $t(`forms.consultation.email`)
              }}</label>
              <input
                v-model.trim="caso.denu_email"
                type="email"
                class="form-control"
                id="email"
                :placeholder="$t(`forms.consultation.email`)"
                disabled
              />
            </div>
          </div>
          <div class="row form">
            <div class="col">
              <label class="form-label">{{
                $t(`forms.consultation.relationship`)
              }}</label>
              <input
                v-model.trim="rela_name"
                type="text"
                class="form-control"
                :placeholder="$t(`forms.consultation.relationship`)"
                disabled
              />
              <label class="form-label">{{
                $t(`forms.consultation.organization`)
              }}</label>
              <input
                v-model.trim="caso.denu_organizacion"
                type="text"
                class="form-control"
                id="organizacion"
                :placeholder="$t(`forms.consultation.organization`)"
                disabled
              />
              <label class="form-label">{{
                $t(`forms.consultation.nature`)
              }}</label>
              <select
                class="form-select inputForm"
                aria-label="Elija una opción"
                v-model.trim="caso.denu_naturaleza_cd"
                disabled
              >
                <option disabled selected value="0">
                  {{ $t(`forms.consultation.nature`) }}
                </option>
                <option
                  v-for="item of natures"
                  :key="item.natu_id"
                  :value="item.natu_id"
                >
                  {{ item.natu_nombre }}
                </option>
              </select>
            </div>
            <div class="col">
              <label class="form-label">{{
                $t(`forms.consultation.message`)
              }}</label>
              <textarea
                v-model.trim="caso.denu_consulta"
                class="form-control"
                :placeholder="$t(`forms.consultation.message`)"
                rows="9"
                disabled
              />
            </div>
          </div>
          <div class="row form">
            <div class="col">
              <label
                class="form-check-label"
                for="flexCheckDefault"
                style="margin-top: 5px; margin-right: 5px"
              >
                {{ $t(`mUser.ocases.isCrime`) }}
              </label>
              <input
                class="form-check-input"
                type="checkbox"
                v-model="isCrime"
                id="flexCheckDefault"
                style="
                  height: 39px;
                  width: 38px;
                  margin-top: -4px;
                  margin-right: 5px;
                "
                disabled
              />
            </div>
            <div class="col">
              <label v-if="isCrime" class="form-label">{{
                $t(`mUser.ocases.crimes`)
              }}</label>
              <textarea
                v-model.trim="caso.denu_tipo_delito"
                class="form-control"
                :placeholder="$t(`mUser.ocases.crimes`)"
                rows="2"
                v-if="isCrime"
                disabled
                style="text-transform: uppercase"
              />
            </div>
          </div>
        </div>
        <div class="card" id="cardForm" v-else>
          <div class="row form" v-if="caso.denu_tipo_denuncia !== 'ANONIMO'">
            <div class="col">
              <label class="form-label">{{
                $t(`forms.consultation.name`)
              }}</label>
              <input
                v-model.trim="caso.denu_nombre"
                type="text"
                class="form-control"
                id="nombre"
                :placeholder="$t(`forms.consultation.name`)"
                disabled
              />
              <label class="form-label">{{
                $t(`forms.consultation.phone`)
              }}</label>
              <input
                v-model.trim="caso.denu_telefono"
                type="phone"
                class="form-control"
                id="telefono"
                :placeholder="$t(`forms.consultation.phone`)"
                disabled
              />
            </div>
            <div class="col">
              <label class="form-label">{{
                $t(`forms.consultation.surname`)
              }}</label>
              <input
                v-model.trim="caso.denu_apellidos"
                type="text"
                class="form-control"
                id="apellido"
                :placeholder="$t(`forms.consultation.surname`)"
                disabled
              />
              <label class="form-label">{{
                $t(`forms.consultation.email`)
              }}</label>
              <input
                v-model.trim="caso.denu_email"
                type="email"
                class="form-control"
                id="email"
                :placeholder="$t(`forms.consultation.email`)"
                disabled
              />
            </div>
          </div>
          <div class="row form">
            <div class="col">
              <label class="form-label">{{
                $t(`forms.consultation.relationship`)
              }}</label>
              <input
                v-model.trim="rela_name"
                type="text"
                class="form-control"
                :placeholder="$t(`forms.consultation.relationship`)"
                disabled
              />
              <label class="form-label" v-if="caso.denu_tipo_denuncia !== 'ANONIMO'">{{
                $t(`forms.consultation.organization`)
              }}</label>
              <input
                v-if="caso.denu_tipo_denuncia !== 'ANONIMO'"
                v-model.trim="caso.denu_organizacion"
                type="text"
                class="form-control"
                id="organizacion"
                :placeholder="$t(`forms.consultation.organization`)"
                disabled
              />
              <label class="form-label">{{
                $t(`forms.consultation.nature`)
              }}</label>
              <select
                class="form-select inputForm"
                aria-label="Elija una opción"
                v-model.trim="caso.denu_naturaleza_cd"
                disabled
              >
                <option disabled selected value="0">
                  {{ $t(`forms.consultation.nature`) }}
                </option>
                <option
                  v-for="item of natures"
                  :key="item.natu_id"
                  :value="item.natu_id"
                >
                  {{ item.natu_nombre }}
                </option>
              </select>
            </div>
            <div class="col">
              <label class="form-label">{{
                $t(`forms.consultation.message`)
              }}</label>
              <textarea
                v-model.trim="caso.denu_consulta"
                class="form-control"
                :placeholder="$t(`forms.consultation.message`)"
                rows="9"
                disabled
              />
            </div>
          </div>
          <div class="row form">
            <div class="col">
              <label class="form-label">{{
                $t(`forms.comp.department`)
              }}</label>
              <select
                class="form-select"
                aria-label="Elija una opción"
                v-model.trim="caso.denu_departamento"
                disabled
              >
                <option :value="0" disabled activate>
                  {{ $t(`forms.comp.department`) }}
                </option>
                <option
                  :value="dep.depa_id"
                  v-for="dep of departamentos"
                  :key="dep.depa_id"
                >
                  {{ dep.depa_nombre }}
                </option>
              </select>
            </div>
            <div class="col">
              <label class="form-label">{{
                $t(`forms.comp.headquarters`)
              }}</label>
              <select
                class="form-select"
                aria-label="Elija una opción"
                v-model.trim="caso.denu_sede"
                disabled
              >
                <option :value="0" disabled activate>
                  {{ $t(`forms.comp.headquarters`) }}
                </option>
                <option
                  :value="sede.sede_id"
                  v-for="sede of sedes"
                  :key="sede.sede_id"
                >
                  {{ sede.sede_nombre }}
                </option>
              </select>
            </div>
          </div>
          <div class="row form">
            <div class="col">
              <label
                class="form-check-label"
                for="flexCheckDefault"
                style="margin-top: 5px; margin-right: 5px"
              >
                {{ $t(`mUser.ocases.isCrime`) }}
              </label>
              <input
                class="form-check-input"
                type="checkbox"
                v-model="isCrime"
                id="flexCheckDefault"
                style="
                  height: 39px;
                  width: 38px;
                  margin-top: -4px;
                  margin-right: 5px;
                "
                disabled
              />
            </div>
            <div class="col">
              <label v-if="isCrime" class="form-label">{{
                $t(`mUser.ocases.crimes`)
              }}</label>
              <textarea
                v-model.trim="caso.denu_tipo_delito"
                class="form-control"
                :placeholder="$t(`mUser.ocases.crimes`)"
                rows="2"
                v-if="isCrime"
                disabled
                maxlength="255"
              />
            </div>
          </div>
          <div class="row form">
              <div class="col">
                <label class="form-label">{{ $t(`forms.comp.when`) }}</label>
                <input
                  v-model.trim="caso.denu_cuando"
                  type="text"
                  class="form-control"
                  disabled
                />
              </div>
              <div class="col">
                <label class="form-label">{{
                  $t(`forms.comp.reiterativo`)
                }}</label>
                <input
                  v-model.trim="caso.denu_reiterativo"
                  type="text"
                  class="form-control"
                  disabled
                />
              </div>
          </div>
          <div class="row form" v-if="caso.denu_resolucion_usuario">
              <div class="col">
                <label class="form-label">{{ $t(`mUser.ocases.phase4.resolucion`) }}</label>
                <textarea
                v-model.trim="caso.denu_resolucion_usuario"
                class="form-control"
                rows="4"
                style="text-transform: uppercase"
                disabled
              />
              </div>
          </div>
        </div>

        <h3 v-if="caso.denu_activo_archivos === 1">
          {{ $t(`mwhistleblower.uploadFile`) }}
        </h3>
        <div class="card" id="cardForm" v-if="caso.denu_activo_archivos === 1">
          <table class="table" id="tableFile">
            <thead>
              <tr>
                <th scope="col">{{ $t("mUser.ocases.files.date") }}</th>
                <th scope="col">{{ $t("mUser.ocases.files.name") }}</th>
                <th scope="col">
                  <button
                    v-if="caso.denu_estado !== 'FINALIZADA'"
                    class="btn button buttonEdit"
                    data-bs-toggle="modal"
                    data-bs-target="#upload"
                    @click="
                      action = 2;
                      result = '';
                    "
                    :style="{ backgroundColor: colorCompany }"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in files" :key="item.defi_id">
                <td style="min-width: 100pt">
                  {{ item.defi_fecha }}
                </td>
                <td>{{ item.defi_nombre }}</td>
                <td>
                  <a
                    class="btn button buttonEdit"
                    target="_blank"
                    :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                    :style="{ backgroundColor: colorCompany }"
                  >
                    <i class="fas fa-download"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h3 v-if="caso.denu_activo_mensajes === 1">
          {{ $t(`mwhistleblower.message`) }}
        </h3>
        <div class="card" id="cardForm" v-if="caso.denu_activo_mensajes === 1">
          <table class="table" id="tableInv">
            <thead>
              <tr>
                <th scope="col">{{ $t("mUser.ocases.authors.date") }}</th>
                <th scope="col">{{ $t("mUser.ocases.authors.name") }}</th>
                <th scope="col">
                  <button
                    v-if="caso.denu_estado !== 'FINALIZADA'"
                    class="btn button buttonEdit"
                    data-bs-toggle="modal"
                    data-bs-target="#message"
                    @click="
                      action = 1;
                      result = '';
                    "
                    :style="{ backgroundColor: colorCompany }"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody style="text-align: justify">
              <tr v-for="item in messages" :key="item.caso_id">
                <td style="min-width: 100pt">
                  {{ item.deme_fecha }}
                </td>
                <td>{{ item.deme_texto }}</td>
                <td>
                  <button
                    class="btn button buttonEdit"
                    @click="seeMessage(item)"
                    style="margin-left: 5px"
                    data-bs-toggle="modal"
                    data-bs-target="#message"
                    :style="{ backgroundColor: colorCompany }"
                  >
                    <i class="fas fa-info-circle"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal upload -->
  <div
    class="modal fade"
    id="upload"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("mUser.ocases.phase4.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanFormUpload()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <FilePond
                ref="pond"
                :label-idle="imgText"
                class="imgFile"
                allow-multiple="true"
                max-files="10"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#responseModal"
            :style="{ backgroundColor: colorCompany }"
            @click="uploadFile()"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            @click="cleanFormUpload()"
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Message -->
  <div
    class="modal fade"
    id="message"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-user">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("mwhistleblower.messageTitle") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForm()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <textarea
                v-model.trim="messageBox"
                class="form-control"
                :placeholder="$t(`mwhistleblower.messagetext`)"
                rows="10"
                style="text-transform: uppercase"
                :disabled="edit"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#responseModal"
            @click="sendMessage()"
            :style="{ backgroundColor: colorCompany }"
            :disabled="edit"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            @click="cleanForm()"
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal response -->
  <div
    class="modal fade"
    id="responseModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("errors.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForm()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3" style="text-align: center">
            <div v-if="loading" class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
              <div class="modal-body" v-if="result === '0' || result === 0">
                {{ $t("errors.0") }}
              </div>
              <div class="modal-body" v-else-if="result === '1'">
                {{ $t("errors.1") }}
              </div>
              <div class="modal-body" v-else-if="result === '2'">
                {{ $t("errors.2") }}
              </div>
              <div class="modal-body" v-else-if="result === '3'">
                {{ $t("errors.3") }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="!loading">
          <button
            v-if="result === '0' || result === 0"
            class="btn button btn-return-result"
            data-bs-dismiss="modal"
            @click="cleanForm()"
          >
            {{ $t("actions.close") }}
          </button>
          <button
            v-if="result !== '0' && action === 1"
            class="btn button btn-return-result"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
            data-bs-target="#message"
          >
            {{ $t("actions.return") }}
          </button>
          <button
            v-if="result !== '0' && action === 2"
            class="btn button btn-return-result"
            data-bs-toggle="modal"
            data-bs-dismiss="modal"
            data-bs-target="#upload"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuVer from "../../components/MenuVer";
import { mapMutations, mapState } from "vuex";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import axios from "axios";
import moment from "moment";

const FilePond = vueFilePond(FilePondPluginImagePreview);

export default {
  components: {
    MenuVer,
    FilePond,
  },
  data() {
    return {
      caso: {},
      denu_id: this.$route.params.id,
      isCrime: false,
      authors: {},
      natures: [],
      rela_name: "",
      messageBox: "",
      edit: false,
      messageId: "",
      result: "",
      loading: false,
      action: 0,
      messages: [],
      emp_id: this.$route.params.idemp,
      files: [],
      imgText: this.$t("forms.imgText"),
    };
  },
  created() {
    this.getDenuncia();
    this.changeBreadCrumb(31);
    this.littleDrop();    
  },
  computed: {
    ...mapState(["colorCompany", "servidor", "id_emp"]),
  },
  methods: {
    ...mapMutations([
      "changeBreadCrumb",
      "littleDrop",
      "changeColorCompanyOrange",
      "changeColorCompanyBlue",
      "changeColorCompanyGreen",
      "changeColorCompanyRed",
      "changeColorCompany4",
      "changeColorCompany5",
      "changeColorCompany6",
      "changeColorCompany7",
      "changeColorCompany8",
      "changeColorCompany9",
      "changeColorCompany10",
      "changeColorCompany11",
      "changeColorCompany12",
      "changeColorCompany13",
      "changeColorCompany14",
      "changeColorCompany15",
      "changeColorCompany16",
      "changeIdEmp",
    ]),
    async getDenuncia() {      
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=denunciadenu&id=${this.denu_id}`
        );
        if (res.data && res.data.data) {
          this.caso = res.data.data[0];
        }
        this.getDepartamentos(this.caso.denu_empresa_cd);
        this.getSedes(this.caso.denu_empresa_cd);
        this.getRelation(this.caso.denu_relacion_cd);
        this.getNatures();
        if (this.caso.denu_activo_mensajes === 1) {
          this.getMessages();
        }
        if (this.caso.denu_activo_archivos === 1) {
          this.getFiles();
        }
        if (this.caso.denu_tipo_delito) {
          this.isCrime = true;
        } else {
          this.isCrime = false;
        }
      } catch (error) {
        console.error(error);
      }      
    },
    async getDepartamentos(id) {
      try {
        const empresa = {
          emp_id: id,
        };
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=departamentos_denu`,
          empresa
        );
        if (res.data) {
          this.departamentos = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getSedes(id) {
      try {
        const empresa = {
          emp_id: id,
        };
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=sedes_denu`,
          empresa
        );
        if (res.data) {
          this.sedes = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getRelation(id) {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=rela&ac=listadoid&id=${id}`
        );
        if (res.data.data) {
          this.rela_name = res.data.data[0].rela_nombre;
        } else if (res.data.RESULT) {
          console.log("No data");
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getNatures() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=natura&ac=listado`
        );
        if (res.data) {
          this.natures = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getMessages() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=denunciante&ac=listarMensajes&id=${this.caso.denu_id}`
        );
        if (res.data) {
          this.messages = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getFiles() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=denunciante&ac=listadoArchivo&id=${this.caso.denu_id}`
        );
        if (res.data) {
          this.files = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    cleanForm() {
      this.messageBox = "";
      this.edit = false;
      this.loading = false;
      this.action = 0;
      this.getDenuncia();
    },
    cleanFormUpload() {
      if (this.$refs.pond) {
        const long = this.$refs.pond.getFiles().length;
        for (let i = 0; i < long; i++) {
          this.$refs.pond.removeFile(i);
        }
      }
      this.action = 0;
      this.loading = false;
    },
    seeMessage(item) {
      this.edit = true;
      this.messageId = item.id;
      this.messageBox = item.deme_texto;
      this.action = 1;
    },
    async sendMessage() {
      this.loading = true;
      let message = {
        deme_texto: this.messageBox.toUpperCase(),
        denu_id: this.caso.denu_id,
      };
      // if (this.edit) {
      //   message.denu_mensaje_id = this.messageId;
      // }
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=denunciante&ac=grabarMensaje`,
          message
        );
        if (res && res.data) {
          this.result = res.data.RESULT;
          this.loading = false;
        }
      } catch (error) {
        console.error(error);
      }
      this.cleanForm();
    },
    async uploadFile() {
      this.loading = true;
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=denunciante&ac=grabarArchivo`,
          this.formatDatas()
        );
        if (res.data) {
          this.result = res.data.RESULT;
          this.loading = false;
        }
      } catch (error) {
        console.error(error);
      }
      this.cleanFormUpload();
    },
    formatDatas() {
      let formData = new FormData();
      formData.append("defi_denuncia_cd", this.caso.denu_id);

      if (this.$refs.pond) {
        for (let i = 0; i < this.$refs.pond.getFiles().length; i++) {
          if (this.$refs.pond.getFile(i)) {
            formData.append(
              `denuncias_archivos_usuarios_${i}`,
              this.$refs.pond.getFile(i).file
            );
          } else {
            console.log("error");
            formData.append(`denu_deci_personas_archivo${i}`, "");
          }
        }
      }
      return formData;
    },
    filterDates(fecha) {
      return moment(fecha).format("MM/DD/YYYY, h:mm:ss");
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

select {
  color: #898f96;
  height: 35pt;
  margin-bottom: 40px;
}

#cardForm {
  margin-top: 40px;
  padding: 25pt;
  display: block;
  margin-bottom: 40px;
}

.close-case {
  color: #fff;
  background-color: #62e4d0;
  border-color: #62e4d0;
  width: 100%;
  border-radius: 20pt;
}

.close-case-phase3 {
  color: #fff;
  background-color: #62e4d0;
  border-color: #62e4d0;
  width: 49.9%;
  border-radius: 20pt;
}

.close-form {
  float: right;
  font-size: 17pt;
  margin-top: -28pt;
}

.export-case {
  margin-top: -30pt;
  margin-right: 70pt;
}

.see-phase2 {
  margin-top: -30pt;
}

.btn-header {
  float: none;
  background-color: cornflowerblue;
  margin-top: 20pt;
  margin-bottom: 20pt;
  margin-right: 5pt;
}

.textboxphase3 {
  margin-bottom: 5pt;
}

.active {
  background-color: orange;
}

.nodeci {
  margin-right: 188pt;
}

.sideci {
  margin-right: 257pt;
}

/* button:focus {
  outline: none;
  box-shadow: 0px 0px 0px 3px rgb(255 128 0 / 20%);
} */
</style>