<template>
  <div class="wrapper">
    <menu-ver type="user" name="User" :id_emp="emp_id" />
    <div class="container m-3">
      <div class="table-header"></div>
      <div class="container" v-if="activeCase">
        <div>
          <button
            type="button"
            class="btn-close close-form"
            @click="closeCardPhase()"
          ></button>
          <button
            type="button"
            class="btn button buttonEdit export-case"
            v-if="activeCase"
            @click="exportCase"
            :style="{ backgroundColor: colorCompany }"
          >
            <i
              class="fas fa-file-export"
              :data-title="$t(`mUser.ocases.exportCase`)"
            ></i>
          </button>
          <button
            type="button"
            class="btn button buttonEdit see-phase2 header-button"
            v-if="phase3 || phase4"
            @click="seePhaseAnalysis(0)"
            :style="{ backgroundColor: colorCompany }"
            :class="{
              nodeci: caso.denu_tipo_denuncia === 'CONSULTA',
              sideci: caso.denu_tipo_denuncia !== 'CONSULTA',
            }"
          >
            {{ $t("mUser.ocases.seephase") }}
          </button>
          <button
            type="button"
            class="btn button buttonEdit see-phase2 header-button"
            v-if="phase3 || (phase4 && caso.denu_tipo_denuncia !== 'CONSULTA')"
            @click="seePhaseAnalysis(1)"
            style="margin-right: 221pt"
            :style="{ backgroundColor: colorCompany }"
          >
            {{ $t("mUser.ocases.phase3.seephase") }}
          </button>
          <button
            type="button"
            class="btn button buttonEdit see-phase2 header-button"
            v-if="phase4"
            @click="seePhaseAnalysis(2)"
            :style="{ backgroundColor: colorCompany }"
            style="margin-right: 120pt"
          >
            {{ $t("mUser.ocases.phase4.seephase") }}
          </button>
        </div>
        <div>
          <div v-if="phase2" id="pruebaexport">
            <h3 v-if="caso.denu_tipo_denuncia === 'ANONIMO'">
              {{ $t(`mUser.ocases.caseType`) }}
              {{ $t(`mUser.ocases.anonymous`) }} - {{ caso.denu_id.substring(0,10) }}
            </h3>
            <h3 v-if="caso.denu_tipo_denuncia === 'CONSULTA'">
              {{ $t(`mUser.ocases.caseType`) }}
              {{ $t(`mUser.ocases.consult`) }} -
              {{ caso.denu_id.substring(0,10) }}
            </h3>
            <h3 v-if="caso.denu_tipo_denuncia === 'NOMINAL'">
              {{ $t(`mUser.ocases.caseType`) }}
              {{ $t(`mUser.ocases.nominal`) }} -
              {{ caso.denu_id.substring(0,10) }}
            </h3>
          </div>
          <div v-else-if="phase3">
            <h3>{{ $t(`mUser.ocases.phase3.title`) }} - {{ caso.denu_id.substring(0,10) }}</h3>
          </div>
          <div v-else-if="phase4">
            <h3>{{ $t(`mUser.ocases.phase4.title`) }} - {{ caso.denu_id.substring(0,10) }}</h3>
          </div>
          <h4 style="font-size: 14pt">
            {{ rute }}
            <br><br>{{ $t(`mUser.ocases.phase4.ultentrada`) }} - {{ caso.denu_ultimo_acceso }}
          </h4>
        </div>

        <div v-if="caso.denu_estado === 'CREADA'">
          <div
            class="card"
            id="cardForm"
            v-if="caso.denu_tipo_denuncia === 'CONSULTA'"
          >
            <div class="row form">
              <div class="col">
                <label class="form-label">{{
                  $t(`forms.consultation.name`)
                }}</label>
                <input
                  v-model.trim="caso.denu_nombre"
                  type="text"
                  class="form-control"
                  id="nombre"
                  :placeholder="$t(`forms.consultation.name`)"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.consultation.phone`)
                }}</label>
                <input
                  v-model.trim="caso.denu_telefono"
                  type="phone"
                  class="form-control"
                  id="telefono"
                  :placeholder="$t(`forms.consultation.phone`)"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.consultation.otromedia`)
                }}</label>
                <input
                  v-model.trim="caso.denu_tipo_dir"
                  type="phone"
                  class="form-control"
                  id="tipo_dir"
                  disabled
                />

              </div>
              <div class="col">
                <label class="form-label">{{
                  $t(`forms.consultation.surname`)
                }}</label>
                <input
                  v-model.trim="caso.denu_apellidos"
                  type="text"
                  class="form-control"
                  id="apellido"
                  :placeholder="$t(`forms.consultation.surname`)"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.consultation.email`)
                }}</label>
                <input
                  v-model.trim="caso.denu_email"
                  type="email"
                  class="form-control"
                  id="email"
                  :placeholder="$t(`forms.consultation.email`)"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.consultation.mediocom`)
                }}</label>
                <input
                  v-model.trim="caso.denu_email2"
                  type="text"
                  class="form-control"
                  id="email2"
                  disabled
                />
              </div>
            </div>
            <div class="row form">
              <div class="col">
                <label class="form-label">{{
                  $t(`forms.consultation.relationship`)
                }}</label>
                <input
                  v-model.trim="rela_name"
                  type="text"
                  class="form-control"
                  :placeholder="$t(`forms.consultation.relationship`)"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.consultation.organization`)
                }}</label>
                <input
                  v-model.trim="caso.denu_organizacion"
                  type="text"
                  class="form-control"
                  id="organizacion"
                  :placeholder="$t(`forms.consultation.organization`)"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.comp.involved`)
                }}</label>
                <input
                  v-model.trim="caso.denu_personas"
                  type="text"
                  class="form-control"
                  id="organizacion"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.consultation.nature`)
                }}</label>
                <select
                  class="form-select inputForm"
                  aria-label="Elija una opción"
                  v-model.trim="caso.denu_naturaleza_cd"
                  :disabled="rol"
                >
                  <option disabled selected value="0">
                    {{ $t(`forms.consultation.nature`) }}
                  </option>
                  <option
                    v-for="item of natures"
                    :key="item.natu_id"
                    :value="item.natu_id"
                  >
                    {{ item.natu_nombre }}
                  </option>
                </select>
              </div>
              <div class="col">
                <label class="form-label">{{
                  $t(`forms.consultation.message`)
                }}</label>
                <textarea
                  v-model.trim="caso.denu_consulta"
                  class="form-control"
                  :placeholder="$t(`forms.consultation.message`)"
                  rows="9"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.consultation.anompo`)
                }}</label>
                <textarea
                  v-model.trim="caso.denu_anonimizado_por"
                  class="form-control"
                  rows="3"
                  disabled
                />    
              </div>
            </div>
            <div class="row form">
              <div class="col">
                <label
                  class="form-check-label"
                  for="flexCheckDefault"
                  style="margin-top: 5px; margin-right: 5px"
                >
                  {{ $t(`mUser.ocases.activateFiles`) }}
                </label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="haveFiles"
                  id="flexCheckDefault"
                  style="
                    height: 39px;
                    width: 38px;
                    margin-top: -4px;
                    margin-right: 5px;
                  "
                  :disabled="rol"
                />
              </div>
              <div class="col">
                <label
                  class="form-check-label"
                  for="flexCheckDefault"
                  style="margin-top: 5px; margin-right: 5px"
                >
                  {{ $t(`mUser.ocases.activateMessages`) }}
                </label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="haveMessages"
                  id="flexCheckDefault"
                  style="
                    height: 39px;
                    width: 38px;
                    margin-top: -4px;
                    margin-right: 5px;
                  "
                  :disabled="rol"
                />
              </div>
            </div>

            <!-- Nuevos botones -->
            
            <div class="row form">
              <button
                class="btn close-case"
                data-bs-toggle="modal"
                data-bs-target="#responseModal"
                @click="saveCase()"
                v-if="!rol"
                style="margin-top: 20pt"
                :style="{ backgroundColor: colorCompany }"
              >
                {{ $t("actions.save") }}
              </button>
            </div>
          </div>
          <div class="card" id="cardForm" v-else>
            <div class="row form" v-if="caso.denu_tipo_denuncia === 'NOMINAL'">
              <div class="col">
                <label class="form-label">{{
                  $t(`forms.consultation.name`)
                }}</label>
                <input
                  v-model.trim="caso.denu_nombre"
                  type="text"
                  class="form-control"
                  id="nombre"
                  :placeholder="$t(`forms.consultation.name`)"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.consultation.phone`)
                }}</label>
                <input
                  v-model.trim="caso.denu_telefono"
                  type="phone"
                  class="form-control"
                  id="telefono"
                  :placeholder="$t(`forms.consultation.phone`)"
                  disabled
                />
                 <label class="form-label">{{
                  $t(`forms.consultation.otromedia`)
                }}</label>
                <input
                  v-model.trim="caso.denu_tipo_dir"
                  type="phone"
                  class="form-control"
                  id="tipo_dir"
                  disabled
                />

              </div>
              <div class="col">
                <label class="form-label">{{
                  $t(`forms.consultation.surname`)
                }}</label>
                <input
                  v-model.trim="caso.denu_apellidos"
                  type="text"
                  class="form-control"
                  id="apellido"
                  :placeholder="$t(`forms.consultation.surname`)"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.consultation.email`)
                }}</label>
                <input
                  v-model.trim="caso.denu_email"
                  type="email"
                  class="form-control"
                  id="email"
                  :placeholder="$t(`forms.consultation.email`)"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.consultation.mediocom`)
                }}</label>
                <input
                  v-model.trim="caso.denu_email2"
                  type="text"
                  class="form-control"
                  id="email2"
                  disabled
                />
              </div>
            </div>
            <div class="row form">
              <div class="col">
                <label class="form-label">{{
                  $t(`forms.consultation.relationship`)
                }}</label>
                <input
                  v-model.trim="rela_name"
                  type="text"
                  class="form-control"
                  :placeholder="$t(`forms.consultation.relationship`)"
                  disabled
                />
                <label
                  class="form-label"
                  v-if="caso.denu_tipo_denuncia === 'NOMINAL'"
                  >{{ $t(`forms.consultation.organization`) }}</label
                >
                <input
                  v-if="caso.denu_tipo_denuncia === 'NOMINAL'"
                  v-model.trim="caso.denu_organizacion"
                  type="text"
                  class="form-control"
                  id="organizacion"
                  :placeholder="$t(`forms.consultation.organization`)"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.comp.involved`)
                }}</label>
                <input
                  v-model.trim="caso.denu_personas"
                  type="text"
                  class="form-control"
                  id="personas"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.consultation.nature`)
                }}</label>
                <select
                  class="form-select inputForm"
                  aria-label="Elija una opción"
                  v-model.trim="caso.denu_naturaleza_cd"
                  :disabled="rol"
                >
                  <option disabled selected value="0">
                    {{ $t(`forms.consultation.nature`) }}
                  </option>
                  <option
                    v-for="item of natures"
                    :key="item.natu_id"
                    :value="item.natu_id"
                  >
                    {{ item.natu_nombre }}
                  </option>
                </select>
              </div>
              <div class="col">
                <label class="form-label">{{
                  $t(`forms.consultation.message`)
                }}</label>
                <textarea
                  v-model.trim="caso.denu_consulta"
                  class="form-control"
                  :placeholder="$t(`forms.consultation.message`)"
                  rows="9"
                  disabled
                />
                
              </div>
            </div>
            <div class="row form">
              <div class="col">
                
                <label class="form-label" style="display: block">{{
                  $t(`mUser.ocases.priorityComplaint`)
                }}</label>
                <select
                  class="form-select"
                  aria-label="Elija una opción"
                  v-model.trim="caso.denu_pertinente"
                  :disabled="rol"
                >
                  <option value="" disabled activate>
                    {{ $t("mUser.ocases.priorityComplaint") }}
                  </option>
                  <option value="ALTA">
                    {{ $t("mUser.ocases.high") }}
                  </option>
                  <option value="MEDIA">
                    {{ $t("mUser.ocases.medio") }}
                  </option>
                  <option value="BAJA">
                    {{ $t("mUser.ocases.low") }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row form">
              <div class="col">
                <label class="form-label">{{ $t(`forms.comp.when`) }}</label>
                <input
                  v-model.trim="caso.denu_cuando"
                  type="text"
                  class="form-control"
                  disabled
                />
              </div>
              <div class="col">
                <label class="form-label">{{
                  $t(`forms.comp.reiterativo`)
                }}</label>
                <input
                  v-model.trim="caso.denu_reiterativo"
                  type="text"
                  class="form-control"
                  disabled
                />
              </div>
            </div>
            <div class="row form">
              <div class="col">
                <label
                  class="form-check-label"
                  for="flexCheckDefault"
                  style="margin-top: 5px; margin-right: 5px"
                >
                  {{ $t(`mUser.ocases.activateFiles`) }}
                </label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="haveFiles"
                  id="flexCheckDefault"
                  style="
                    height: 39px;
                    width: 38px;
                    margin-top: -4px;
                    margin-right: 5px;
                  "
                  :disabled="rol"
                />
              </div>
              <div class="col">
                <label
                  class="form-check-label"
                  for="flexCheckDefault"
                  style="margin-top: 5px; margin-right: 5px"
                >
                  {{ $t(`mUser.ocases.activateMessages`) }}
                </label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="haveMessages"
                  id="flexCheckDefault"
                  style="
                    height: 39px;
                    width: 38px;
                    margin-top: -4px;
                    margin-right: 5px;
                  "
                  :disabled="rol"
                />
              </div>
            </div>

            <!-- Nuevos botones -->
                        <div class="row form">
              <button
                class="btn close-case"
                data-bs-toggle="modal"
                data-bs-target="#responseModal"
                @click="saveCase()"
                v-if="!rol"
                style="margin-top: 20pt"
                :style="{ backgroundColor: colorCompany }"
              >
                {{ $t("actions.save") }}
              </button>
            </div>

          </div>

          <h3>
            {{ $t(`mUser.ocases.uploadFile`) }}
          </h3>
          <div class="card" id="cardForm">
            <table class="table" id="tableFile">
              <thead>
                <tr>
                  <th scope="col">{{ $t("mUser.ocases.files.date") }}</th>
                  <th scope="col">{{ $t("mUser.ocases.files.name") }}</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in files" :key="item.dear_id">
                  <td>{{ item.defi_fecha }}</td>
                  <td>{{ item.defi_nombre }}</td>
                  <td>
                    <a
                      class="btn button buttonEdit"
                      target="_blank"
                      :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${caso.denu_id}&defi_id=${item.defi_id}`"
                      :style="{ backgroundColor: colorCompany }"
                    >
                      <i class="fas fa-download"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3>
            {{ $t(`mUser.ocases.investigation`) }}
          </h3>
          <div class="card" id="cardForm">
            <table class="table" id="tableInv">
              <thead>
                <tr>
                  <th scope="col">{{ $t("mUser.ocases.authors.date") }}</th>
                  <th scope="col">{{ $t("mUser.ocases.authors.name") }}</th>
                  <th scope="col">
                    <button
                      class="btn button buttonEdit"
                      data-bs-toggle="modal"
                      data-bs-target="#investigacion"
                      @click="addInvestigation()"
                      v-if="!rol"
                      :style="{ backgroundColor: colorCompany }"
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in authors" :key="item.caso_id">
                  <td>{{ item.dein_fecha }}</td>
                  <td>{{ item.usu_nombre }}</td>
                  <td>
                    <button
                      class="btn button buttonEdit"
                      @click="editInv(item)"
                      style="margin-left: 5px"
                      data-bs-toggle="modal"
                      data-bs-target="#investigacion"
                      :style="{ backgroundColor: colorCompany }"
                    >
                      <i class="fas fa-info-circle"></i>
                    </button>
                    <!-- <button
                    class="btn button buttonDelete"
                    @click="deleteInv(item)"
                    data-bs-toggle="modal"
                    data-bs-target="#deleteModal"
                  >
                    <i class="fas fa-minus"></i>
                  </button> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="changePhase">
            <div class="mb-3 mt-4">
              <label class="form-label">{{
                $t(`mUser.ocases.phase3.conclusionPhase2`)
              }}</label>
              <textarea
                class="form-control textboxphase3"
                rows="7"
                disabled
                v-model="caso.denu_final"
                style="text-transform: uppercase"
              />
            </div>
            <div class="mb-3 mt-4">
              <label class="form-label">{{
                $t(`mUser.ocases.phase3.cause`)
              }}</label>
              <textarea
                class="form-control textboxphase3"
                rows="7"
                disabled
                v-model="caso.denu_causas"
                style="text-transform: uppercase"
              />
            </div>
            <div class="mb-3 mt-4">
              <label class="form-label">{{
                $t(`mUser.ocases.phase3.observationphase2`)
              }}</label>
              <textarea
                class="form-control textboxphase3"
                rows="7"
                disabled
                v-model="caso.denu_fase2_observacion"
                style="text-transform: uppercase"
              />
            </div>
            <p v-if="caso.denu_procedente === 1">
              {{ $t(`mUser.ocases.phase3.relevant`) }}
            </p>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesDenuFichFase2"
              :key="item.defi_id"
            >
              <span style="float: right; width: 100%"
                >{{ item.defi_nombre }}
                <a
                  class="btn button buttonEdit"
                  :style="{ backgroundColor: colorCompany }"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${caso.denu_id}&defi_id=${item.defi_id}`"
                  style="
                    margin-right: 5pt;
                    margin-left: 5pt;
                    margin-top: -5pt;
                    margin-bottom: 10pt;
                  "
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
          </div>
          <button
            class="btn close-case"
            @click="closeCase()"
            data-bs-toggle="modal"
            data-bs-target="#closephase2"
            v-if="caso.denu_tipo_denuncia === 'CONSULTA' && !rol"
            style="margin-bottom: 20pt"
            :style="{ backgroundColor: colorCompany }"
          >
            {{ $t("mUser.ocases.close") }}
          </button>
          <button
            class="btn close-case"
            @click="closeCase()"
            data-bs-toggle="modal"
            data-bs-target="#closephase2"
            v-else-if="caso.denu_tipo_denuncia !== 'CONSULTA' && !rol"
            style="margin-bottom: 40pt"
            :style="{ backgroundColor: colorCompany }"
          >
            {{ $t("mUser.ocases.closedenu") }}
          </button>
          <br><br>


        </div>

        <div v-if="caso.denu_estado === 'ANALISIS'">
          <div class="mb-3 mt-4">
            <label class="form-label">{{
              $t(`mUser.ocases.phase3.message`)
            }}</label>
            <textarea
              class="form-control textboxphase3"
              rows="7"
              disabled
              v-model="caso.denu_consulta"
              style="text-transform: uppercase"
            />
          </div>

          <div class="mb-3 mt-4">
            <label class="form-label">{{
              $t(`mUser.ocases.phase3.decisionfinal`)
            }}</label>
            <textarea
              class="form-control textboxphase3"
              rows="7"
              v-model="caso.denu_decision_final"
              style="text-transform: uppercase"
              :disabled="disableDeci"
            />
          </div>

          <button
            class="btn close-case-phase3"
            style="
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              margin-left: 1pt;
            "
            v-if="!disableDeci"
            data-bs-toggle="modal"
            data-bs-target="#closephase3"
            :style="{ backgroundColor: colorCompany }"
          >
            {{ $t("mUser.ocases.phase3.close") }}
          </button>
          <button
            class="btn close-case-phase3"
            @click="saveCasePhase3()"
            style="border-bottom-right-radius: 0; border-top-right-radius: 0"
            v-if="!disableDeci"
            data-bs-toggle="modal"
            data-bs-target="#responseModal"
            :style="{ backgroundColor: colorCompany }"
          >
            {{ $t("mUser.ocases.phase3.save") }}
          </button>

          <div style="text-align: center">
            
          </div>

          <div class="mb-3 card p-3" v-if="people">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase3.people`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesDeciPeople"
              :key="item.defi_id"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <button
                  class="btn button buttonTrash"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteModal"
                  @click="getId(item.defi_id, 3)"
                  style="margin-top: -5pt; margin-bottom: 10pt"
                >
                  <i class="fas fa-trash"></i>
                </button>
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="txtpeople"
              :disabled="disableDeci"
              style="text-transform: uppercase"
            />
            <FilePond
              ref="filepeople"
              :label-idle="imgText"
              class="imgFile"
              allow-multiple="true"
              max-files="10"
              v-if="!disableDeci"
            />
          </div>
          <div class="mb-3 card p-3" v-if="compliance">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase3.compliance`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesDeciCompl"
              :key="item.defi_id"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <button
                  class="btn button buttonTrash"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteModal"
                  @click="getId(item.defi_id, 3)"
                  style="margin-top: -5pt; margin-bottom: 10pt"
                >
                  <i class="fas fa-trash"></i>
                </button>
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="txtcompliance"
              :disabled="disableDeci"
              style="text-transform: uppercase"
            />
            <FilePond
              ref="filecompliance"
              :label-idle="imgText"
              class="imgFile"
              allow-multiple="true"
              max-files="10"
              v-if="!disableDeci"
            />
          </div>
          <div class="mb-3 card p-3" v-if="organization">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase3.organization`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesDeciOrg"
              :key="item.defi_id"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <button
                  class="btn button buttonTrash"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteModal"
                  @click="getId(item.defi_id, 3)"
                  style="margin-top: -5pt; margin-bottom: 10pt"
                >
                  <i class="fas fa-trash"></i>
                </button>
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="txtorganization"
              :disabled="disableDeci"
              style="text-transform: uppercase"
            />
            <FilePond
              ref="fileorganization"
              :label-idle="imgText"
              class="imgFile"
              allow-multiple="true"
              max-files="10"
              v-if="!disableDeci"
            />
          </div>
          <div class="mb-3 card p-3" v-if="compliant">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase3.compliant`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesDeciOim"
              :key="item.defi_id"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <button
                  class="btn button buttonTrash"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteModal"
                  @click="getId(item.defi_id, 3)"
                  style="margin-top: -5pt; margin-bottom: 10pt"
                >
                  <i class="fas fa-trash"></i>
                </button>
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="txtcompliant"
              :disabled="disableDeci"
              style="text-transform: uppercase"
            />
            <FilePond
              ref="filecompliant"
              :label-idle="imgText"
              class="imgFile"
              allow-multiple="true"
              max-files="10"
              v-if="!disableDeci"
            />
          </div>
          <div class="mb-3 card p-3" v-if="comunication">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase3.comunication`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesDeciComu"
              :key="item.defi_id"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <button
                  class="btn button buttonTrash"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteModal"
                  @click="getId(item.defi_id, 3)"
                  style="margin-top: -5pt; margin-bottom: 10pt"
                >
                  <i class="fas fa-trash"></i>
                </button>
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="txtcomunication"
              :disabled="disableDeci"
              style="text-transform: uppercase"
            />
            <FilePond
              ref="filecomunication"
              :label-idle="imgText"
              class="imgFile"
              allow-multiple="true"
              max-files="10"
              v-if="!disableDeci"
            />
          </div>
          <div class="mb-3 card p-3" v-if="other">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase3.other`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesDeciOtros"
              :key="item.defi_id"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <button
                  class="btn button buttonTrash"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteModal"
                  @click="getId(item.defi_id, 3)"
                  style="margin-top: -5pt; margin-bottom: 10pt"
                >
                  <i class="fas fa-trash"></i>
                </button>
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="txtother"
              :disabled="disableDeci"
              style="text-transform: uppercase"
            />
            <FilePond
              ref="fileother"
              :label-idle="imgText"
              class="imgFile"
              allow-multiple="true"
              max-files="10"
              v-if="!disableDeci"
            />
          </div>

          <div class="mb-3" v-if="changePhase && phase4">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase3.observation`) }}
            </label>
            <textarea
              class="form-control"
              rows="5"
              v-model.trim="caso.denu_fase3_observacion"
              disabled
            />
          </div>
        </div>

        <div v-if="caso.denu_estado === 'DECISION'">
          <div class="mb-3 mt-4">
            <label
              class="form-label"
              v-if="caso.denu_tipo_denuncia === 'CONSULTA'"
              >{{ $t(`mUser.ocases.phase4.message`) }}</label
            >
            <label class="form-label" v-else>{{
              $t(`mUser.ocases.phase4.messagedenu`)
            }}</label>
            <textarea
              class="form-control textboxphase3"
              rows="7"
              disabled
              v-model="caso.denu_consulta"
              style="text-transform: uppercase"
            />
          </div>

          <button
            class="btn close-case-phase3"
            style="
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              margin-left: 1pt;
            "
            data-bs-toggle="modal"
            data-bs-target="#closephase4"
            :style="{ backgroundColor: colorCompany }"
          >
            {{ $t("mUser.ocases.phase4.close") }}
          </button>
          <button
            class="btn close-case-phase3"
            @click="saveCasePhase4()"
            style="border-bottom-right-radius: 0; border-top-right-radius: 0"
            data-bs-toggle="modal"
            data-bs-target="#responseModal"
            :style="{ backgroundColor: colorCompany }"
          >
            {{ $t("mUser.ocases.phase4.save") }}
          </button>

          <div style="text-align: center">

          </div>

          <div class="mb-3 card p-3" v-if="cpeople">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase4.people`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesComuPeo"
              :key="item.defi_id"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <button
                  class="btn button buttonTrash"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteModal"
                  @click="getId(item.defi_id, 4)"
                  style="margin-top: -5pt; margin-bottom: 10pt"
                >
                  <i class="fas fa-trash"></i>
                </button>
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="caso.denu_comu_personas"
              style="text-transform: uppercase"
            />
            <FilePond
              ref="cfilepeople"
              :label-idle="imgText"
              class="imgFile"
              allow-multiple="true"
              max-files="10"
            />
            <select
              class="form-select"
              v-model.trim="caso.denu_comu_personas_canal"
            >
              <option value="null" disabled>
                {{ $t("mUser.ocases.phase4.channel") }}
              </option>
              <option value="TELEFONO">
                {{ $t("mUser.ocases.phase4.phone") }}
              </option>
              <option value="CORREOELECTRONICO">
                {{ $t("mUser.ocases.phase4.email") }}
              </option>
              <option value="OTROS">
                {{ $t("mUser.ocases.phase4.otherchannel") }}
              </option>
            </select>
            <label class="form-check-label">
              {{ $t(`forms.email`) }}
            </label>

                <input
                  v-model.trim="caso.denu_comu_personas_email"
                  type="text"
                  class="form-control"
                />
            <label class="form-check-label">
              {{ $t(`actions.send`) }}
            </label>
                <input
                class="form-check-input"
                type="checkbox"
                value="1"
                v-model.trim="caso.denu_comu_personas_enviar"
                style="
                  height: 39px;
                  width: 38px;
                  margin-top: -4px;
                  margin-right: 5px;
                "
              />
                </div>
          <div class="mb-3 card p-3" v-if="cresponsible">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase4.responsible`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesComuRes"
              :key="item.defi_id"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <button
                  class="btn button buttonTrash"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteModal"
                  @click="getId(item.defi_id, 4)"
                  style="margin-top: -5pt; margin-bottom: 10pt"
                >
                  <i class="fas fa-trash"></i>
                </button>
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="caso.denu_comu_responsable"
              style="text-transform: uppercase"
            />
            <FilePond
              ref="cfilecompliance"
              :label-idle="imgText"
              class="imgFile"
              allow-multiple="true"
              max-files="10"
            />
            <select
              class="form-select"
              v-model.trim="caso.denu_comu_responsable_canal"
            >
              <option value="null" disabled>
                {{ $t("mUser.ocases.phase4.channel") }}
              </option>
              <option value="TELEFONO">
                {{ $t("mUser.ocases.phase4.phone") }}
              </option>
              <option value="CORREOELECTRONICO">
                {{ $t("mUser.ocases.phase4.email") }}
              </option>
              <option value="OTROS">
                {{ $t("mUser.ocases.phase4.otherchannel") }}
              </option>
            </select>
                        <label class="form-check-label">
              {{ $t(`forms.email`) }}
            </label>

                <input
                  v-model.trim="caso.denu_comu_responsable_email"
                  type="text"
                  class="form-control"
                />
            <label class="form-check-label">
              {{ $t(`actions.send`) }}
            </label>
                <input
                class="form-check-input"
                type="checkbox"
                value="1"
                v-model.trim="caso.denu_comu_responsable_enviar"
                style="
                  height: 39px;
                  width: 38px;
                  margin-top: -4px;
                  margin-right: 5px;
                "
              />

          </div>
          <div class="mb-3 card p-3" v-if="corganization">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase4.organization`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesComuOrg"
              :key="item.defi_id"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <button
                  class="btn button buttonTrash"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteModal"
                  @click="getId(item.defi_id, 4)"
                  style="margin-top: -5pt; margin-bottom: 10pt"
                >
                  <i class="fas fa-trash"></i>
                </button>
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="caso.denu_comu_organizacion"
              style="text-transform: uppercase"
            />
            <FilePond
              ref="cfileorganization"
              :label-idle="imgText"
              class="imgFile"
              allow-multiple="true"
              max-files="10"
            />
            <select
              class="form-select"
              v-model.trim="caso.denu_comu_organizacion_canal"
            >
              <option value="null" disabled>
                {{ $t("mUser.ocases.phase4.channel") }}
              </option>
              <option value="TELEFONO">
                {{ $t("mUser.ocases.phase4.phone") }}
              </option>
              <option value="CORREOELECTRONICO">
                {{ $t("mUser.ocases.phase4.email") }}
              </option>
              <option value="OTROS">
                {{ $t("mUser.ocases.phase4.otherchannel") }}
              </option>
            </select>
                        <label class="form-check-label">
              {{ $t(`forms.email`) }}
            </label>

                <input
                  v-model.trim="caso.denu_comu_organizacion_email"
                  type="text"
                  class="form-control"
                />
            <label class="form-check-label">
              {{ $t(`actions.send`) }}
            </label>
                <input
                class="form-check-input"
                type="checkbox"
                value="1"
                v-model.trim="caso.denu_comu_organizacion_enviar"
                style="
                  height: 39px;
                  width: 38px;
                  margin-top: -4px;
                  margin-right: 5px;
                "
              />

          </div>
          <div class="mb-3 card p-3" v-if="ccompliant">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase4.compliant`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesComuDen"
              :key="item.defi_id"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <button
                  class="btn button buttonTrash"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteModal"
                  @click="getId(item.defi_id, 4)"
                  style="margin-top: -5pt; margin-bottom: 10pt"
                >
                  <i class="fas fa-trash"></i>
                </button>
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="caso.denu_comu_denunciante"
              style="text-transform: uppercase"
            />


            <FilePond
              ref="cfilecompliant"
              :label-idle="imgText"
              class="imgFile"
              allow-multiple="true"
              max-files="10"
            />
            <select
              class="form-select"
              v-model.trim="caso.denu_comu_denunciante_canal"
            >
              <option value="null" disabled>
                {{ $t("mUser.ocases.phase4.channel") }}
              </option>
              <option value="TELEFONO">
                {{ $t("mUser.ocases.phase4.phone") }}
              </option>
              <option value="CORREOELECTRONICO">
                {{ $t("mUser.ocases.phase4.email") }}
              </option>
              <option value="OTROS">
                {{ $t("mUser.ocases.phase4.otherchannel") }}
              </option>
            </select>
                        <label class="form-check-label">
              {{ $t(`forms.email`) }}
            </label>

            <input
                  v-model.trim="caso.denu_comu_denunciante_email"
                  type="text"
                  class="form-control"
                />
            <label class="form-check-label">
              {{ $t(`actions.send`) }}
            </label>
                <input
                class="form-check-input"
                type="checkbox"
                value="1"
                v-model.trim="caso.denu_comu_denunciante_enviar"
                style="
                  height: 39px;
                  width: 38px;
                  margin-top: -4px;
                  margin-right: 5px;
                "
              />
          </div>
          <div class="mb-3 card p-3" v-if="cadminpublica">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase4.adminpublic`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesComuApb"
              :key="item.defi_id"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <button
                  class="btn button buttonTrash"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteModal"
                  @click="getId(item.defi_id, 4)"
                  style="margin-top: -5pt; margin-bottom: 10pt"
                >
                  <i class="fas fa-trash"></i>
                </button>
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="caso.denu_comu_adminpublica"
              style="text-transform: uppercase"
            />
            <FilePond
              ref="cfilecomunication"
              :label-idle="imgText"
              class="imgFile"
              allow-multiple="true"
              max-files="10"
            />
            <select
              class="form-select"
              v-model.trim="caso.denu_comu_adminpublica_canal"
            >
              <option value="null" disabled>
                {{ $t("mUser.ocases.phase4.channel") }}
              </option>
              <option value="TELEFONO">
                {{ $t("mUser.ocases.phase4.phone") }}
              </option>
              <option value="CORREOELECTRONICO">
                {{ $t("mUser.ocases.phase4.email") }}
              </option>
              <option value="OTROS">
                {{ $t("mUser.ocases.phase4.otherchannel") }}
              </option>
            </select>
                        <label class="form-check-label">
              {{ $t(`forms.email`) }}
            </label>

                <input
                  v-model.trim="caso.denu_comu_adminpublica_email"
                  type="text"
                  class="form-control"
                />
            <label class="form-check-label">
              {{ $t(`actions.send`) }}
            </label>
                <input
                class="form-check-input"
                type="checkbox"
                value="1"
                v-model.trim="caso.denu_comu_adminpublica_enviar"
                style="
                  height: 39px;
                  width: 38px;
                  margin-top: -4px;
                  margin-right: 5px;
                "
              />

          </div>
          <div class="mb-3 card p-3" v-if="cother">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase4.other`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesComuOtros"
              :key="item.defi_id"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <button
                  class="btn button buttonTrash"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteModal"
                  @click="getId(item.defi_id, 4)"
                  style="margin-top: -5pt; margin-bottom: 10pt"
                >
                  <i class="fas fa-trash"></i>
                </button>
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <input
              style="text-transform: uppercase"
              type="text"
              class="form-control"
              v-model="caso.denu_comu_otra_interesada"
            />
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="caso.denu_comu_otras"
              style="text-transform: uppercase"
            />
            <FilePond
              ref="cfileother"
              :label-idle="imgText"
              class="imgFile"
              allow-multiple="true"
              max-files="10"
            />
            <select
              class="form-select"
              v-model.trim="caso.denu_comu_otras_canal"
            >
              <option value="null" disabled>
                {{ $t("mUser.ocases.phase4.channel") }}
              </option>
              <option value="TELEFONO">
                {{ $t("mUser.ocases.phase4.phone") }}
              </option>
              <option value="CORREOELECTRONICO">
                {{ $t("mUser.ocases.phase4.email") }}
              </option>
              <option value="OTROS">
                {{ $t("mUser.ocases.phase4.otherchannel") }}
              </option>
            </select>
                                    <label class="form-check-label">
              {{ $t(`forms.email`) }}
            </label>

                <input
                  v-model.trim="caso.denu_comu_otras_email"
                  type="text"
                  class="form-control"
                />
            <label class="form-check-label">
              {{ $t(`actions.send`) }}
            </label>
                <input
                class="form-check-input"
                type="checkbox"
                value="1"
                v-model.trim="caso.denu_comu_otras_enviar"
                style="
                  height: 39px;
                  width: 38px;
                  margin-top: -4px;
                  margin-right: 5px;
                "
              />
          </div>
          <div class="mb-3 card p-3" v-if="cother1">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase4.other`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesComuOtros1"
              :key="item.defi_id"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <button
                  class="btn button buttonTrash"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteModal"
                  @click="getId(item.defi_id, 4)"
                  style="margin-top: -5pt; margin-bottom: 10pt"
                >
                  <i class="fas fa-trash"></i>
                </button>
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <input
              style="text-transform: uppercase"
              type="text"
              class="form-control"
              v-model="caso.denu_comu_otra1_interesada"
            />
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="caso.denu_comu_otras1"
              style="text-transform: uppercase"
            />
            <FilePond
              ref="cfileother1"
              :label-idle="imgText"
              class="imgFile"
              allow-multiple="true"
              max-files="10"
            />
            <select
              class="form-select"
              v-model.trim="caso.denu_comu_otras1_canal"
            >
              <option value="null" disabled>
                {{ $t("mUser.ocases.phase4.channel") }}
              </option>
              <option value="TELEFONO">
                {{ $t("mUser.ocases.phase4.phone") }}
              </option>
              <option value="CORREOELECTRONICO">
                {{ $t("mUser.ocases.phase4.email") }}
              </option>
              <option value="OTROS">
                {{ $t("mUser.ocases.phase4.otherchannel") }}
              </option>
            </select>
          </div>
          <div class="mb-3 card p-3" v-if="cother2">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase4.other`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesComuOtros2"
              :key="item.defi_id"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <button
                  class="btn button buttonTrash"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteModal"
                  @click="getId(item.defi_id, 4)"
                  style="margin-top: -5pt; margin-bottom: 10pt"
                >
                  <i class="fas fa-trash"></i>
                </button>
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <input
              style="text-transform: uppercase"
              type="text"
              class="form-control"
              v-model="caso.denu_comu_otra2_interesada"
            />
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="caso.denu_comu_otras2"
              style="text-transform: uppercase"
            />
            <FilePond
              ref="cfileother2"
              :label-idle="imgText"
              class="imgFile"
              allow-multiple="true"
              max-files="10"
            />
            <select
              class="form-select"
              v-model.trim="caso.denu_comu_otras2_canal"
            >
              <option value="null" disabled>
                {{ $t("mUser.ocases.phase4.channel") }}
              </option>
              <option value="TELEFONO">
                {{ $t("mUser.ocases.phase4.phone") }}
              </option>
              <option value="CORREOELECTRONICO">
                {{ $t("mUser.ocases.phase4.email") }}
              </option>
              <option value="PRESENCIAL">
                {{ $t("mUser.ocases.phase4.presencial") }}
              </option>
              <option value="OTROS">
                {{ $t("mUser.ocases.phase4.otherchannel") }}
              </option>
            </select>
          </div>
        </div>
        <div v-if="InviUsu && InviUsu.length > 0">
          <h3>
            {{ $t(`mUser.ocases.InviUsu.title`) }}
          </h3>
          <div class="card" id="cardForm">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">{{ $t("mUser.ocases.InviUsu.name") }}</th>
                  <th scope="col">{{ $t("mUser.ocases.InviUsu.motivo") }}</th>
                </tr>
              </thead>
              <tbody style="text-align: justify">
                <tr v-for="item in InviUsu" :key="item.defi_id">
                  <td style="min-width: 100pt">
                    {{ item.usde_nombre }}
                  </td>
                  <td>{{ item.usde_texto }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div v-if="filesUsu && filesUsu.length > 0">
          <h3>
            {{ $t(`mUser.ocases.filesUsu.title`) }}
          </h3>
          <div class="card" id="cardForm">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">{{ $t("mUser.ocases.files.date") }}</th>
                  <th scope="col">{{ $t("mUser.ocases.files.name") }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody style="text-align: justify">
                <tr v-for="item in filesUsu" :key="item.defi_id">
                  <td style="min-width: 100pt">
                    {{ item.defi_fecha }}
                  </td>
                  <td>{{ item.defi_nombre }}</td>
                  <td>
                    <a
                      class="btn button buttonEdit"
                      target="_blank"
                      :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                      :style="{ backgroundColor: colorCompany }"
                    >
                      <i class="fas fa-download"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          v-if="
            caso.denu_activo_mensajes === 1 || caso.denu_activo_mensajes === '1'
          "
        >
          <h3>
            {{ $t(`mUser.ocases.messages.title`) }}
          </h3>
          <div class="card" id="cardForm">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">{{ $t("mUser.ocases.messages.date") }}</th>
                  <th scope="col">{{ $t("mUser.ocases.messages.text") }}</th>
                  <th>
                    <button
                      class="btn button buttonEdit"
                      data-bs-toggle="modal"
                      data-bs-target="#message"
                      @click="messageBox = ''"
                      v-if="!rol"
                      :style="{ backgroundColor: colorCompany }"
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody style="text-align: justify">
                <tr v-for="item in messages" :key="item.deme_id">
                  <td style="min-width: 100pt">
                    {{ item.deme_fecha }}
                  </td>
                  <td>{{ item.deme_texto }}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div v-if="!activeCase && cases && cases.length > 0">
          <div class="row">
            <div class="col">
            <input
              type="text"
              class="form-control"
              id="filtroCode"
              :placeholder="$t(`mUser.ocases.code`)"
              style="text-transform: uppercase"
              v-model="filtroCode"
              v-on:change="filtroDenuncias"
            />
            </div><div class="col">
            <input
              type="text"
              class="form-control"
              id="filtroTipo"
              :placeholder="$t(`mUser.ocases.type`)"
              style="text-transform: uppercase"
              v-model="filtroTipo"
              v-on:change="filtroDenuncias"
            /></div><div class="col">
            <input
              type="text"
              class="form-control"
              id="filtroDepa"
              :placeholder="$t(`statistics.relation`)"
              style="text-transform: uppercase"
              v-model="filtroDepa"
              v-on:change="filtroDenuncias"
            /></div><div class="col">
            <input
              type="text"
              class="form-control"
              id="filtroFase"
              :placeholder="$t(`mUser.ocases.phase`)"
              style="text-transform: uppercase"
              v-model="filtroFase"
              v-on:change="filtroDenuncias"
            /></div>
          </div>
                    <div class="row">
            <div class="col">
            <input
              type="text"
              class="form-control"
              id="filtroNatu"
              :placeholder="$t(`mUser.ocases.nature`)"
              style="text-transform: uppercase"
              v-model="filtroNatu"
              v-on:change="filtroDenuncias"
            />
            </div><div class="col">
            <input
              type="text"
              class="form-control"
              id="filtroPrior"
              :placeholder="$t(`statistics.priority`)"
              style="text-transform: uppercase"
              v-model="filtroPrior"
              v-on:change="filtroDenuncias"
            /></div>
          </div>
      </div>
      <table class="table" v-if="!activeCase && cases && cases.length > 0">
        <thead>
          <tr>
            <th scope="col">{{ $t("mUser.ocases.code") }}</th>
            <th scope="col">{{ $t("mUser.ocases.date") }}</th>
            <th scope="col">{{ $t("mUser.ocases.type") }}</th>
            <th scope="col">{{ $t("mUser.ocases.nature") }}</th>
            <th scope="col">{{ $t("statistics.relation") }}</th>
            <th scope="col">{{ $t("statistics.priority") }}</th>
            <th scope="col">{{ $t("mUser.ocases.phase") }}</th>
            <th scope="col">{{ $t("mUser.ocases.time") }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in filterCases" :key="item.denu_id">
            <td>{{ item.denu_id.substring(0,10) }}</td>
            <td>{{ item.denu_fecha_alta }}</td>
            <td>
              <p v-if="item.denu_tipo_denuncia === 'CONSULTA'">
                {{ $t("mUser.ocases.consult") }}
              </p>
              <p v-if="item.denu_tipo_denuncia === 'NOMINAL'">
                {{ $t("mUser.ocases.nominal") }}
              </p>
              <p v-if="item.denu_tipo_denuncia === 'ANONIMO'">
                {{ $t("mUser.ocases.anonymous") }}
              </p>
            </td>
            <td>{{ item.denu_nature }}</td>
            <td>{{ item.denu_relacion }}</td>
            <td>{{ item.denu_prioridad }}</td>
            <td>
              <p v-if="item.denu_estado === 'CREADA'">
                {{ $t("mUser.ocases.analysis") }}
              </p>
              <p v-if="item.denu_estado === 'ANALISIS'">
                {{ $t("mUser.ocases.decision") }}
              </p>
              <p v-if="item.denu_estado === 'DECISION'">
                {{ $t("mUser.ocases.comunication") }}
              </p>
              <p v-if="item.denu_estado === 'FINALIZADA'">
                {{ $t("mUser.ocases.finished") }}
              </p>
            </td>
            <td>
              <p v-if="parseDate(item.denu_fecha_alta)<-90">
                {{ $t("mUser.ocases.outtime") }}
              </p>
              <p v-if="parseDate(item.denu_fecha_alta)>-90">
                {{ $t("mUser.ocases.intime") }}
              </p>
            </td>

            <td>
              <button
                class="btn button buttonEdit"
                @click="seeCase(item)"
                :style="{ backgroundColor: colorCompany }"
              >
                <i class="fas fa-search"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-if="!activeCase && cases && cases.length <= 0">
        {{ $t("errors.3") }}
      </p>
    </div>
  </div>

  <!-- Modal Invesitgaciones -->
  <div
    class="modal fade"
    id="investigacion"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("mUser.ocases.invPhase.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanFormInv()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3" v-if="phaseCase === 0">
            <div v-if="action === 0">
              <div class="mb-3">
                <label class="form-label">{{
                  $t(`mUser.ocases.invPhase.text`)
                }}</label>
                <textarea
                  v-model.trim="author.dein_texto"
                  class="form-control"
                  :placeholder="$t(`mUser.ocases.invPhase.text`)"
                  rows="9"
                  style="text-transform: uppercase"
                />
              </div>
              <div class="mb-3">
                <FilePond
                  ref="pond"
                  :label-idle="imgText"
                  class="imgFile"
                  allow-multiple="true"
                  max-files="10"
                />
              </div>
            </div>
            <div v-else>
              <div v-if="usu_same && !rol">
                <div class="mb-3">
                  <label class="form-label">{{
                    $t(`mUser.ocases.invPhase.text`)
                  }}</label>
                  <textarea
                    v-model.trim="author.dein_texto"
                    class="form-control"
                    :placeholder="$t(`mUser.ocases.invPhase.text`)"
                    rows="9"
                    style="text-transform: uppercase"
                  />
                </div>
                <div class="mb-3">
                  <FilePond
                    ref="pond"
                    :label-idle="imgText"
                    class="imgFile"
                    allow-multiple="true"
                    max-files="10"
                  />
                </div>
                <div
                  class="mb-3 margin-top: 5pt;"
                  v-for="item in filesDenuFich"
                  :key="item.defi_id"
                >
                  <span style="float: right; width: 100%; margin-bottom: 5pt"
                    >{{ item.defi_nombre }}
                    <button
                      class="btn button buttonEdit"
                      data-bs-dismiss="modal"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteModal"
                      @click="getId(item.defi_id, 0)"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                    <a
                      class="btn button buttonEdit"
                      target="_blank"
                      :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                      style="margin-right: 5pt; margin-left: 5pt"
                      :style="{ backgroundColor: colorCompany }"
                    >
                      <i class="fas fa-download"></i>
                    </a>
                  </span>
                </div>
              </div>
              <div v-else>
                <div class="mb-3">
                  <label class="form-label">{{
                    $t(`mUser.ocases.invPhase.text`)
                  }}</label>
                  <textarea
                    v-model.trim="author.dein_texto"
                    class="form-control"
                    :placeholder="$t(`mUser.ocases.invPhase.text`)"
                    rows="9"
                    style="text-transform: uppercase"
                    disabled
                  />
                </div>
                <div class="mb-3">
                  <div
                    class="margin-top: 5pt;"
                    v-for="item in filesDenuFich"
                    :key="item.defi_id"
                  >
                    <span style="float: right; width: 100%"
                      >{{ item.defi_nombre }}
                      <button
                        class="btn button buttonEdit"
                        data-bs-dismiss="modal"
                        data-bs-toggle="modal"
                        data-bs-target="#deleteModal"
                        @click="getId(item.defi_id, 0)"
                        style="margin-top: -5pt; margin-bottom: 10pt"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                      <a
                        class="btn button buttonEdit"
                        target="_blank"
                        :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                        style="
                          margin-right: 5pt;
                          margin-left: 5pt;
                          margin-top: -5pt;
                        "
                        :style="{ backgroundColor: colorCompany }"
                      >
                        <i class="fas fa-download"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="px-4 py-3" v-if="phaseCase === 1">
            <div class="mb-3">
              <textarea
                v-model.trim="closephase2.denu_final"
                class="form-control"
                :placeholder="
                  $t(`mUser.ocases.invPhase.conclusionPhase2`) + ' *'
                "
                rows="7"
                style="text-transform: uppercase"
              />
            </div>
            <div class="mb-3">
              <textarea
                v-model.trim="closephase2.denu_causas"
                class="form-control"
                :placeholder="$t(`mUser.ocases.invPhase.cause`) + ' *'"
                rows="7"
                style="text-transform: uppercase"
              />
            </div>
            <div class="mb-3">
              <textarea
                v-model.trim="closephase2.denu_fase2_observaciones"
                class="form-control"
                :placeholder="$t(`mUser.ocases.invPhase.observation`)"
                rows="7"
                style="text-transform: uppercase"
              />
            </div>
            <div
              class="col"
              style="margin-bottom: -28px"
              v-if="caso.denu_tipo_denuncia !== 'CONSULTA'"
            >
              <label
                class="form-check-label"
                for="flexCheckDefault"
                style="margin-top: 5px; margin-right: 5px"
              >
                {{ $t(`mUser.ocases.invPhase.relevant`) }}
              </label>
              <input
                class="form-check-input"
                type="checkbox"
                v-model="isRelevant"
                style="
                  height: 39px;
                  width: 38px;
                  margin-top: -4px;
                  margin-right: 5px;
                "
              />
            </div>
            <div class="mb-3">
              <FilePond
                ref="archivoFinal"
                :label-idle="imgText"
                class="imgFile"
                allow-multiple="true"
                max-files="10"
              />
            </div>
            <span
              v-if="formClosePhase2Error"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ $t("errors.fieldOb") }}</span
            >
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#responseModal"
            @click="saveInv"
            v-if="action === 0"
            :style="{ backgroundColor: colorCompany }"
          >
            {{ $t("actions.save") }}
          </button>
          <button
            id="btnSend"
            type="button"
            class="btn-light btnCrud"
            @click="closeCasePhase2"
            v-else-if="action === 2"
            :style="{ backgroundColor: colorCompany }"
          >
            {{ $t("actions.save") }}
          </button>
          <button
            id="btnSend"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#responseModal"
            @click="editSendInv"
            v-else-if="action === 1"
            :disabled="rol || !usu_same"
            :style="{ backgroundColor: colorCompany }"
          >
            {{ $t("actions.save") }}
          </button>
          <button
            @click="cleanFormInv(true)"
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal delete Archivos-->
  <div
    class="modal fade"
    id="deleteModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("mUser.ocases.deleteTitle") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          {{ $t("mUser.ocases.deleteMessage") }}
        </div>
        <div class="modal-footer" style="justify-content: center">
          <button
            id="btnDelete"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            @click="confirmDeleteFiles"
          >
            {{ $t("maintenance.actions.confirm") }}
          </button>
          <button
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#investigacion"
          >
            {{ $t("maintenance.actions.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Close Phase 2 -->
  <div
    class="modal fade"
    id="closephase2"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-edit">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("mUser.ocases.phase3.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanFormInv()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <textarea
                v-model.trim="closephase2.denu_final"
                class="form-control"
                :placeholder="
                  $t(`mUser.ocases.invPhase.conclusionPhase2`) + ' *'
                "
                rows="7"
                style="text-transform: uppercase"
              />
            </div>
            <div class="mb-3">
              <textarea
                v-model.trim="closephase2.denu_causas"
                class="form-control"
                :placeholder="$t(`mUser.ocases.invPhase.cause`) + ' *'"
                rows="7"
                style="text-transform: uppercase"
              />
            </div>
            <div class="mb-3">
              <textarea
                v-model.trim="closephase2.denu_fase2_observaciones"
                class="form-control"
                :placeholder="$t(`mUser.ocases.invPhase.observation`)"
                rows="7"
                style="text-transform: uppercase"
              />
            </div>
            <div
              class="col"
              style="margin-bottom: -28px"
              v-if="caso.denu_tipo_denuncia !== 'CONSULTA'"
            >
              <label
                class="form-check-label"
                for="flexCheckDefault"
                style="margin-top: 5px; margin-right: 5px"
              >
                {{ $t(`mUser.ocases.invPhase.relevant`) }}
              </label>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  v-model="caso.denu_procedente"
                  value="1"
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  {{ $t(`mUser.ocases.invPhase.yes`) }}
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  checked
                  v-model="caso.denu_procedente"
                  value="0"
                />
                <label class="form-check-label" for="flexRadioDefault2">
                  {{ $t(`mUser.ocases.invPhase.no`) }}
                </label>
              </div>
              <!-- <input
                class="form-check-input"
                type="checkbox"
                v-model="isRelevant"
                style="
                  height: 39px;
                  width: 38px;
                  margin-top: -4px;
                  margin-right: 5px;
                "
              /> -->
            </div>
            <div class="mb-3" style="margin-top: 30pt">
              <FilePond
                ref="archivoFinal"
                :label-idle="imgText"
                class="imgFile"
                allow-multiple="true"
                max-files="10"
              />
            </div>
            <span
              v-if="formClosePhase2Error"
              class="text-danger"
              style="float: right; font-size: 12px"
              >{{ $t("errors.fieldOb") }}</span
            >
          </div>
        </div>
        <div class="modal-footer">
          <div>
              <label
                class="form-check-label"
                for="flexCheckDefault"
                style="margin-top: 5px; margin-right: 5px"
              >
                {{ $t("mUser.ocases.mensajeConfirmacion") }}
              </label>
              <input
                class="form-check-input"
                type="checkbox"
                v-model="canClosePhase"
                style="
                  height: 39px;
                  width: 38px;
                  margin-top: -4px;
                  margin-right: 5px;
                "
              />
          </div>
          <button
            id="btnSend"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#responseModal"
            @click="closeCasePhase2()"
            :style="{ backgroundColor: colorCompany }"
            :disabled="!canClosePhase"
          >
            {{ $t("actions.cerrarFase") }}
          </button>
          <button
            @click="cleanFormInv()"
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Close Phase 3 -->
  <div
    class="modal fade"
    id="closephase3"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-edit">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("mUser.ocases.phase3.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanFormInv()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <textarea
                v-model.trim="txtobservation"
                class="form-control"
                :placeholder="$t(`mUser.ocases.invPhase.observation`)"
                rows="7"
                style="text-transform: uppercase"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div>
              <label
                class="form-check-label"
                for="flexCheckDefault"
                style="margin-top: 5px; margin-right: 5px"
              >
                {{ $t("mUser.ocases.mensajeConfirmacion") }}
              </label>
              <input
                class="form-check-input"
                type="checkbox"
                v-model="canClosePhase"
                style="
                  height: 39px;
                  width: 38px;
                  margin-top: -4px;
                  margin-right: 5px;
                "
              />
          </div>
          <button
            id="btnSend"
            type="button"
            class="btn-light btnCrud"
            @click="closeCasePhase3()"
            :style="{ backgroundColor: colorCompany }"
            :disabled="!canClosePhase"
          >
            {{ $t("actions.cerrarFase") }}
          </button>
          <button
            @click="cleanFormInv()"
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Close Phase 4 -->
  <div
    class="modal fade"
    id="closephase4"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-edit">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("mUser.ocases.phase4.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanFormInv()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">            
            <div class="mb-3">
              <textarea
                v-model.trim="resolucionUsuario"
                class="form-control"
                :placeholder="$t(`mUser.ocases.phase4.resolucion`)"
                rows="7"
                style="text-transform: uppercase"
              />
            </div>
            <div class="mb-3">
              <textarea
                v-model.trim="caso.denu_fase4_observacion"
                class="form-control"
                :placeholder="$t(`mUser.ocases.invPhase.observation`)"
                rows="7"
                style="text-transform: uppercase"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div>
              <label
                class="form-check-label"
                for="flexCheckDefault"
                style="margin-top: 5px; margin-right: 5px"
              >
                {{ $t("mUser.ocases.mensajeConfirmacion") }}
              </label>
              <input
                class="form-check-input"
                type="checkbox"
                v-model="canClosePhase"
                style="
                  height: 39px;
                  width: 38px;
                  margin-top: -4px;
                  margin-right: 5px;
                "
              />
          </div>
          <button
            id="btnSend"
            type="button"
            class="btn-light btnCrud"
            @click="closeCasePhase4()"
            :style="{ backgroundColor: colorCompany }"
            :disabled="!canClosePhase"
          >
            {{ $t("actions.cerrarFase") }}
          </button>
          <button
            @click="cleanFormInv()"
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Message -->
  <div
    class="modal fade"
    id="message"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-user">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("mwhistleblower.messageTitle") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cleanForm()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="mb-3">
              <textarea
                v-model.trim="messageBox"
                class="form-control"
                :placeholder="$t(`mwhistleblower.messagetext`)"
                rows="10"
                style="text-transform: uppercase"
                :disabled="edit"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#responseModal"
            @click="sendMessage()"
            :style="{ backgroundColor: colorCompany }"
            :disabled="edit"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            @click="cleanForm()"
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal response -->
  <div
    class="modal fade"
    id="responseModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("errors.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3" style="text-align: center">
            <div v-if="loading" class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
               
              <div class="modal-body" v-if="result === '0'">
                {{ $t("errors.0") }}
              </div>
              <div class="modal-body" v-else-if="result === '1'">
                {{ $t("errors.1") }}
              </div>
              <div class="modal-body" v-else-if="result === '2'">
                {{ $t("errors.2") }}
              </div>
              <div class="modal-body" v-else-if="result === '3'">
                {{ $t("errors.3") }}
              </div>
              <div class="modal-body" v-else-if="result === '7'">
                {{ $t("errors.7") }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="!loading">
          <button class="btn button btn-return-result" data-bs-dismiss="modal">
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal tipos de datos o denuncia -->
  <div
    class="modal fade"
    id="typeModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="tipoModalExtraInfo == 'ajena'">
            {{ $t("mUser.ocases.ajenos") }}
          </h5>
          <h5 class="modal-title" v-if="tipoModalExtraInfo == 'sensible'">
            {{ $t("mUser.ocases.sensibles") }}
          </h5>
          <h5 class="modal-title" v-if="tipoModalExtraInfo == 'falsa'">
            {{ $t("mUser.ocases.falsa") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div
              class="col"
              v-if="tipoModalExtraInfo == 'falsa'"
            >
              <label
                class="form-check-label"
                for="flexCheckDefault"
                style="margin-top: 5px; margin-right: 5px"
              >
                {{ $t(`mUser.ocases.falseMensaje`) }}
              </label>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                  v-model="accionesLegales"
                  value="1"
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  {{ $t(`mUser.ocases.invPhase.yes`) }}
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  checked
                  v-model="accionesLegales"
                  value="0"
                />
                <label class="form-check-label" for="flexRadioDefault2">
                  {{ $t(`mUser.ocases.invPhase.no`) }}
                </label>
              </div>
            </div>            
            <div class="mb-3">
              <textarea
                v-model.trim="textoBorrado"
                class="form-control"
                rows="10"
                style="text-transform: uppercase"
              />
                <label class="form-check-label" for="flexRadioDefault2">
                  {{ $t(`mUser.ocases.avisoFAS`) }}
                </label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#responseModal"
            @click="sendTipoModalExtraInfo()"
            :style="{ backgroundColor: colorCompany }"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            @click="cleanFormDenunciaFalsa()"
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal anadir usuarios -->
  <div
    class="modal fade"
    id="anadirusuario"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ $t("mUser.ocases.anadirusuario") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3">
            <div class="col">
              <label class="form-label">{{
                  $t(`forms.consultation.name`)
              }}</label>
              <input
                  v-model.trim="usu_nombre"
                  type="text"
                  class="form-control"
                  :placeholder="$t(`forms.consultation.name`)"
                />
            </div>
            <div class="col">
              <label class="form-label">{{
                $t(`forms.consultation.email`)
              }}</label>
              <input
                v-model.trim="usu_email"
                type="email"
                class="form-control"
                :placeholder="$t(`forms.consultation.email`)"
                />
              </div>
            <div class="col">
              <label class="form-label">{{
                $t(`forms.password`)
              }}</label>
              <input
                v-model.trim="usu_clave"
                type="text"
                class="form-control"
                :placeholder="$t(`forms.password`)"
                />
              </div>
            <div class="mb-3">
              <label class="form-label">{{
                $t(`mUser.ocases.InviUsu.motivo`)
              }}</label>
              <textarea
                v-model.trim="usu_texto"
                class="form-control"
                rows="10"
                style="text-transform: uppercase"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnSend"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
            data-bs-toggle="modal"
            data-bs-target="#responseModal"
            @click="anadirUsuario()"
            :style="{ backgroundColor: colorCompany }"
          >
            {{ $t("actions.send") }}
          </button>
          <button
            @click="cleanFormanadirUsuario()"
            id="btnClean"
            type="button"
            class="btn-light btnCrud"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuVer from "../../../components/MenuVer";
import moment from "moment";
import { mapMutations, mapState } from "vuex";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import axios from "axios";

const FilePond = vueFilePond(FilePondPluginImagePreview);

export default {
  components: {
    MenuVer,
    FilePond,
  },
  data() {
    moment.locale("es");
    return {
      cases: [],
      filterCases: [],
      authors: [],
      author: {},
      files: [],
      natures: [],
      fonts: [],
      activeCase: false,
      caso: {},
      closephase2: {
        denu_final: "",
        denu_causas: "",
        denu_fase2_observaciones: "",
        denu_archivo_final_id: "",
      },
      isCrime: false,
      haveFiles: false,
      haveMessages: false,
      imgText: this.$t("forms.imgText"),
      phaseCase: null,
      emp_id: this.$route.params.idemp,
      departamentos: [],
      sedes: [],
      rela_name: "",
      result: "",
      loading: "",
      action: 0,
      rol: false,
      fileInv: "",
      usu_same: false,
      isRelevant: false,
      isRelevant2: false,
      // Variables para fase 3
      people: false,
      compliance: false,
      organization: false,
      compliant: false,
      comunication: false,
      other: false,
      observation: false,
      txtpeople: "",
      txtcompliance: "",
      txtorganization: "",
      txtcompliant: "",
      txtcomunication: "",
      txtother: "",
      txtobservation: "",
      bpeople: "",
      bcompliance: "",
      borganization: "",
      bcompliant: "",
      bcomunication: "",
      bother: "",
      // Variables para fase 4
      cpeople: false,
      cresponsible: false,
      corganization: false,
      ccompliant: false,
      cadminpublica: false,
      cother: false,
      cother1: false,
      cother2: false,
      bcpeople: "",
      bcresponsible: "",
      bcorganization: "",
      bccompliant: "",
      bcadminpublica: "",
      bcother: "",
      bcother1: "",
      bcother2: "",
      formClosePhase2Error: false,
      changePhase: false,
      phase4: false,
      phase3: false,
      phase2: false,
      filesDenuFich: [],
      filesDenuFichFase2: [],
      filesDenuFichFase3: [],
      filesDeciPeople: [],
      filesDeciCompl: [],
      filesDeciOrg: [],
      filesDeciOim: [],
      filesDeciComu: [],
      filesDeciOtros: [],
      filesDenuFichFase4: [],
      filesComuPeo: [],
      filesComuRes: [],
      filesComuOrg: [],
      filesComuDen: [],
      filesComuApb: [],
      filesComuOtros: [],
      filesComuOtros1: [],
      filesComuOtros2: [],
      idDelete: "",
      phaseDelete: 0,
      tableToExport: false,
      messages: [],
      filesUsu: [],
      InviUsu: [],
      rute: "",
      messageBox: "",
      disableDeci: false,
      tipoModalExtraInfo: "",
      textoBorrado: "",
      accionesLegales: 0,
      resolucionUsuario: "",
      //anadir usuario
      usu_email: '',
      usu_nombre: '',
      usu_texto: '',
      usu_clave:'',
      filtroCode: '',
      filtroDepa: '',
      filtroTipo: '',
      filtroFase: '',
      filtroNatu: '',
      filtroDepa2: '',
      filtroSede: '',
      filtroPrior: '',
      canClosePhase: false,
      actualCase: ''
    };
  },
  created() {
    this.changeBreadCrumb(21);
    this.bigDrop();
    this.getDenuncias('');
    this.getColorType();
  },
  computed: {
    ...mapState(["id_usu", "usu_rol", "colorCompany", "servidor"]),
  },
  methods: {
    ...mapMutations([
      "changeBreadCrumb",
      "bigDrop",
      "changeColorCompanyGreen",
      "changeColorCompanyBlue",
      "changseeCaseeColorCompanyRed",
      "changeColorCompanyOrange",
      "changeColorCompany4",
      "changeColorCompany5",
      "changeColorCompany6",
      "changeColorCompany7",
      "changeColorCompany8",
      "changeColorCompany9",
      "changeColorCompany10",
      "changeColorCompany11",
      "changeColorCompany12",
      "changeColorCompany13",
      "changeColorCompany14",
      "changeColorCompany15",
      "changeColorCompany16",
    ]),
    parseDate (start) {
        return moment(start, 'DD/MM/YYYY HH:mm').diff(Date(), "days");
    },
    seeCase(item) {
      this.actualCase = item;
      this.phase2 = false;
      this.phase3 = false;
      this.phase4 = false;
      this.activeCase = true;
      //this.result = "";
      this.changePhase = false;
      this.filesDenuFichFase2 = [];
      this.disabledFields();
      this.getDenuncia(item.denu_id);
      this.seeMessages(item.denu_id);
      this.getFilesUsu(item.denu_id);
      this.getInviUsu(item.denu_id);
      // Para pruebas borrar para producción
      // this.caso = item;
      // this.getDatasCases();
    },
    addInvestigation() {
      this.phaseCase = 0;
      this.action = 0;
      this.cleanFormInv();
    },
    async getDenuncias() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=listado&id=${this.emp_id}`
        );
        if (res.data) {
          this.cases = res.data.data;
          this.filterCases = this.cases;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async filtroDenuncias() {
        var fTipo;
        var fFase;
        fFase = this.filtroFase.toString().toUpperCase();
        if (fFase.indexOf("AN")>=0) {
          fFase = "CREADA";
          }

        if (fFase.indexOf("DE")>=0) fFase = "ANALISIS";
        if (fFase.indexOf("CO")>=0) fFase = "DECISION";

        fTipo = this.filtroTipo.toString().toUpperCase().replace("Ó","O");
        fTipo = fTipo.replace("ANONIMA","ANONIMO");
        this.filterCases = this.cases.filter((c) => c.denu_id.toString().toUpperCase().includes(this.filtroCode.toString().toUpperCase())).filter((c) => c.denu_relacion.toString().toUpperCase().includes(this.filtroDepa.toString().toUpperCase())).filter((c) => c.denu_tipo_denuncia.toString().toUpperCase().includes(fTipo)).filter((c) => c.denu_estado.toString().toUpperCase().includes(fFase)).filter((c) => c.denu_nature.toString().toUpperCase().includes(this.filtroNatu.toString().toUpperCase())).filter((c) => c.denu_prioridad.toString().toUpperCase().includes(this.filtroPrior.toString().toUpperCase()));
    },
    async getDenuncia(id) {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=denuncia&id=${id}`
        );
        if (res.data && res.data.data) {
          this.caso = res.data.data[0];
          this.rute = this.caso.ruta;
          this.getDatasCases();
        }
      } catch (error) {
        console.error(error);
      }
    },
    getDatasCases() {
      if (this.caso.denu_estado === "CREADA") {
        this.changeBreadCrumb(25);
        this.phase2 = true;
        if (this.caso.denu_tipo_delito) {
          this.isCrime = true;
        } else {
          this.isCrime = false;
        }

        if (this.caso.denu_activo_archivos === 1) {
          this.haveFiles = true;
        } else {
          this.haveFiles = false;
        }

        if (this.caso.denu_activo_mensajes === 1) {
          this.haveMessages = true;
        } else {
          this.haveMessages = false;
        }

        if (this.caso.denu_naturaleza_cd === null) {
          this.caso.denu_naturaleza_cd = 0;
        }
        if (this.caso.denu_pertinente === null) {
          this.caso.denu_pertinente = 0;
        }

        this.getRelation(this.caso.denu_relacion_cd);
        this.getNatures();
        this.getInv(this.caso.denu_id);
        this.getFiles(this.caso.denu_id);
      } else if (this.caso.denu_estado === "ANALISIS") {
        this.changeBreadCrumb(26);
        this.disableDeci = false;
        this.phase3 = true;
        if (
          this.caso.denu_deci_personas &&
          this.caso.denu_deci_personas.length > 0
        ) {
          this.people = true;
          this.txtpeople = this.caso.denu_deci_personas;
        } else {
          this.txtpeople = "";
          this.people = false;
        }

        if (
          this.caso.denu_deci_compliance &&
          this.caso.denu_deci_compliance.length > 0
        ) {
          this.txtcompliance = this.caso.denu_deci_compliance;
          this.compliance = true;
        } else {
          this.txtcompliance = "";
          this.compliance = false;
        }

        if (
          this.caso.denu_deci_organizacion &&
          this.caso.denu_deci_organizacion.length > 0
        ) {
          this.txtorganization = this.caso.denu_deci_organizacion;
          this.organization = true;
        } else {
          this.txtorganization = "";
          this.organization = false;
        }

        if (
          this.caso.denu_deci_otras_implicadas &&
          this.caso.denu_deci_otras_implicadas.length > 0
        ) {
          this.txtcompliant = this.caso.denu_deci_otras_implicadas;
          this.compliant = true;
        } else {
          this.txtcompliant = "";
          this.compliant = false;
        }

        if (
          this.caso.denu_deci_comunicacion &&
          this.caso.denu_deci_comunicacion.length > 0
        ) {
          this.txtcomunication = this.caso.denu_deci_comunicacion;
          this.comunication = true;
        } else {
          this.txtcomunication = "";
          this.comunication = false;
        }

        if (this.caso.denu_deci_otras && this.caso.denu_deci_otras.length > 0) {
          this.txtother = this.caso.denu_deci_otras;
          this.other = true;
        } else {
          this.txtother = "";
          this.other = false;
        }
        this.getFilesFase3();
        this.changeActiveButtonDeci();
      } else if (this.caso.denu_estado === "DECISION") {
        this.changeBreadCrumb(27);
        this.disableDeci = false;
        this.phase4 = true;
        if (
          this.caso.denu_comu_personas &&
          this.caso.denu_comu_personas.length > 0
        ) {
          this.cpeople = true;
        } else {
          this.cpeople = false;
          this.caso.denu_comu_personas = "";
        }

        if (
          this.caso.denu_comu_responsable &&
          this.caso.denu_comu_responsable.length > 0
        ) {
          this.cresponsible = true;
        } else {
          this.cresponsible = false;
          this.caso.denu_comu_responsable = "";
        }

        if (
          this.caso.denu_comu_organizacion &&
          this.caso.denu_comu_organizacion.length > 0
        ) {
          this.corganization = true;
        } else {
          this.corganization = false;
          this.caso.denu_comu_organizacion = "";
        }

        if (
          this.caso.denu_comu_denunciante &&
          this.caso.denu_comu_denunciante.length > 0
        ) {
          this.ccompliant = true;
        } else {
          this.ccompliant = false;
          this.caso.denu_comu_denunciante = "";
        }

        if (
          this.caso.denu_comu_adminpublica &&
          this.caso.denu_comu_adminpublica.length > 0
        ) {
          this.cadminpublica = true;
        } else {
          this.cadminpublica = false;
          this.caso.denu_comu_adminpublica = "";
        }

        if (this.caso.denu_comu_otras && this.caso.denu_comu_otras.length > 0) {
          this.cother = true;getDatasCases
        } else {
          this.cother = false;
          this.caso.denu_comu_otras = "";
        }

        if (
          this.caso.denu_comu_otras1 &&
          this.caso.denu_comu_otras1.length > 0
        ) {
          this.cother1 = true;
        } else {
          this.cother1 = false;
          this.caso.denu_comu_otras1 = "";
        }

        if (
          this.caso.denu_comu_otras2 &&
          this.caso.denu_comu_otras2.length > 0
        ) {
          this.cother2 = true;
        } else {
          this.cother2 = false;
          this.caso.denu_comu_otras2 = "";
        }
        this.getFilesFase4();
        this.changeActiveButtonComu();
      }
    },
    async getDepartamentos() {
      try {
        const empresa = {
          emp_id: this.emp_id,
        };
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=departamentos`,
          empresa
        );
        if (res.data) {
          this.departamentos = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getSedes() {
      try {
        const empresa = {
          emp_id: this.emp_id,
        };
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=sedes`,
          empresa
        );
        if (res.data) {
          this.sedes = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getRelation(id) {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=rela&ac=listadoid&id=${id}`
        );
        if (res.data.data) {
          this.rela_name = res.data.data[0].rela_nombre;
        } else if (res.data.RESULT) {
          console.log("No data");
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getNatures() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=natura&ac=listado`
        );
        if (res.data) {
          this.natures = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async sendTipoModalExtraInfo() {
      try {
        const data = {
          denu_texto_borrado:this.textoBorrado ,
          denu_acciones_legales: this.accionesLegales,
          denu_id: this.caso.denu_id
        };

        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=${this.tipoModalExtraInfo}`,
          data
        );
        //console.log(res);
        if (res.data) {
          this.result = res.data.RESULT;
          location.reload();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getInv(id) {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=investigacion&ac=listado&id=${id}`
        );
        if (res.data) {
          this.authors = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async seeMessages(id) {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=denunciante&ac=listarMensajes&id=${id}`
        );
        if (res.data) {
          this.messages = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getFilesUsu(id) {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=denunciante&ac=listadoArchivo&id=${id}`
        );
        if (res.data) {
          this.filesUsu = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getInviUsu(id) {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=denunciante&ac=listadoInvi&id=${id}`
        );
        if (res.data) {
          this.InviUsu = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getFiles(id) {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=listarArchivosDenuncia&id=${id}`
        );
        if (res.data) {
          this.files = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async saveInv() {
      if (this.phaseCase === 0) {
        try {
          this.loading = true;
          const res = await axios.post(
            `${this.servidor}api/op_denuncia.asp?tp=investigacion&ac=grabar`,
            this.formatDataInv()
          );
          if (res.data) {
            this.result = res.data.RESULT;
          }
        } catch (error) {
          console.error(error);
        }
        this.cleanFormInv();
      } else {
        try {
          this.loading = true;
          const res = await axios.post(
            `${this.servidor}api/op_denuncia.asp?tp=investigacion&ac=grabar`,
            this.formatDataInv()
          );
          if (res.data) {
            this.result = res.data.RESULT;
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    editInv(item) {
      this.action = 1;
      this.author = item;

      this.result = "";

      if (this.author.dein_usuario_cd === this.id_usu) {
        this.usu_same = true;
      } else {
        this.usu_same = false;
      }

      this.phaseCase = 0;

      if (this.$refs.pond && this.$refs.pond.getFile(0)) {
        this.$refs.pond.removeFile(0);
      }

      this.getFilesDenuFich("INVES");
    },
    async getFilesDenuFich(type) {
      let id = "";

      if (type === "INVES") {
        id = this.author.dein_id;
      } else if (type === "FASE2") {
        id = this.caso.denu_id;
      }

      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=listarArchivos&id=${id}&type=${type}`
        );

        if (res.data) {
          if (type === "INVES") {
            this.filesDenuFich = res.data.data;
          } else if (type === "FASE2") {
            this.filesDenuFichFase2 = res.data.data;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async editSendInv() {
      try {
        this.loading = true;
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=investigacion&ac=actualizar`,
          this.formatDataInv()
        );
        if (res.data) {
          this.result = res.data.RESULT;
        }
      } catch (error) {
        console.error(error);
      }
      this.cleanFormInv();
    },
    async deleteInv(item) {
      this.loading = true;
      const borrar = {
        dein_id: item.dein_id,
      };
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=investigacion&ac=borrar`,
          borrar
        );
        this.loading = false;
        if (res.data) {
          this.result = res.data.RESULT;
        }
      } catch (error) {
        this.loading = false;
        console.error(error);
      }
    },
    cleanFormInv(ret = false) {
      this.author = {};
      this.loading = false;
      if (!ret) {
        this.getInv(this.caso.denu_id);
      }

      if (this.$refs.pond) {
        const lenghtPond = this.$refs.pond.getFiles().length;
        for (let i = 0; i < lenghtPond; i++) {
          this.$refs.pond.removeFile(i);
        }
      }

      this.filesDenuFich = [];
    },
    saveMasInformacion(tipo) {
      this.tipoModalExtraInfo = tipo;
      if (tipo=='ajena') this.textoBorrado = "Su denuncia va a ser anonimizada por incluir datos personales ajenos a los hechos denunciados. Por favor, envíe de nuevo la denuncia sin incluir esos datos."
      if (tipo=='sensible') this.textoBorrado = "Su denuncia va a ser anonimizada por incluir datos personales pertenecientes a las categorías especiales de datos. Por favor, envíe de nuevo la denuncia sin incluir esos datos."
      if (tipo=='falsa') this.textoBorrado = "Su denuncia va a ser anonimizada inmediatamente. Tras realizar las investigaciones oportunas, hemos acreditado que parte de la información facilitada no es veraz."
    },
    async saveCase() {
      if (!this.isCrime) {
        this.caso.denu_delito = "";
      }

      let archivos = "0";
      let mensajes = "0";
      if (this.haveFiles) {
        archivos = "1";
      }
      if (this.haveMessages) {
        mensajes = "1";
      }

      const data = {
        denu_id: this.caso.denu_id,
        denu_naturaleza_cd: this.caso.denu_naturaleza_cd.toString(),
        denu_tipo_delito: this.caso.denu_tipo_delito,
        denu_departamento: this.caso.denu_departamento.toString(),
        denu_sede: this.caso.denu_sede.toString(),
        denu_pertinente: this.caso.denu_pertinente,
        denu_activo_mensajes: mensajes,
        denu_activo_archivos: archivos,
      };
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=actualizar`,
          data
        );
        if (res.data) {
          //console.log(res.data);
          this.result = res.data.RESULT;
          this.seeCase(this.actualCase);         
        }
      } catch (error) {
        console.error(error);
      }
    },
    closeCase() {
      this.action = 2;
      this.phaseCase = 1;
      this.closephase2.denu_final = "";
      this.closephase2.denu_causas = "";
      this.closephase2.denu_fase2_observaciones = "";
      this.closephase2.denu_archivo_final_id = "";
      this.caso.denu_procedente = 0;
      this.isRelevant = false;
      if (this.$refs.archivoFinal && this.$refs.archivoFinal.getFile(0)) {
        this.$refs.archivoFinal.removeFile(this.$refs.archivoFinal.getFile(0));
      }
    },
    async anadirUsuario() {
      try {
          
          const data = {
          usu_nombre:this.usu_nombre ,
          usu_email: this.usu_email,
          usu_texto: this.usu_texto,
          usu_clave: this.usu_clave,
          denu_id: this.caso.denu_id
        };
          const res = await axios.post(
            `${this.servidor}api/op_denuncia.asp?tp=analisis&ac=anadirusuario`,
            data
          );
          if (res.data) {
            this.result = res.data.RESULT;
            this.cleanFormanadirUsuario();
          }
        } catch (error) {
          console.error(error);
          if (error.message.includes("code 403")) {
            this.result = "7";
          }
          
        }
    },
    cleanFormanadirUsuario() {
      this.usu_nombre = '';
      this.usu_email = '';
      this.usu_texto = '';
      this.usu_clave = '';
    },
    cleanFormDenunciaFalsa() {
      this.textoBorrado = '';
      this.accionesLegales = '';
      this.tipoModalExtraInfo = '';
    },
    cleanFormCloseCase(phase) {
      if (phase === 2) {
        if (this.$refs.archivoFinal) {
          const lenghtPond = this.$refs.archivoFinal.getFiles().length;
          for (let i = 0; i < lenghtPond; i++) {
            this.$refs.archivoFinal.removeFile(i);
          }
        }
      } else if (phase === 3) {
        this.txtpeople = "";
        this.txtcompliance = "";
        this.txtorganization = "";
        this.txtcompliant = "";
        this.txtcomunication = "";
        this.txtother = "";
        this.txtobservation = "";
      } else if (phase === 4) {
      }
      this.loading = false;
      this.activeCase = false;
      this.getDenuncias();
      this.canClosePhase = false;
    },
    async closeCasePhase2() {
      if (this.validatePhase2()) {
        this.loading = true;
        try {
          $("#investigacion").modal("hide");
          $("#responseModal").modal("show");

          const tieneFirma = await axios.post(
            `${this.servidor}api/op_denuncia.asp?tp=datos&ac=tieneFirma&id=${this.id_usu}`
          );
          if (tieneFirma.data.usu_firma === 1) {
            const sign = await axios.post(`${this.servidor}api/sign/cert.asp`);
          }
          const res = await axios.post(
            `${this.servidor}api/op_denuncia.asp?tp=datos&ac=cerrarfase2`,
            this.formatDataClosePhase2()
          );
          if (res.data) {
            this.result = res.data.RESULT;
          }
        } catch (error) {
          console.error(error);
          if (error.message.includes("code 403")) {
            this.result = "7";
          }
        }
        this.cleanFormCloseCase(2);
      }
    },
    validatePhase2() {
      if (
        !this.closephase2.denu_final ||
        this.closephase2.denu_final.length <= 0
      ) {
        this.formClosePhase2Error = true;
        return false;
      }
      if (
        !this.closephase2.denu_causas ||
        this.closephase2.denu_causas.length <= 0
      ) {
        this.formClosePhase2Error = true;
        return false;
      }
      this.formClosePhase2Error = false;
      return true;
    },
    formatDatas() {
      let formData = new FormData();

      formData.append("denu_nombre", this.caso.denu_nombre.toUpperCase());
      formData.append("denu_apellidos", this.caso.denu_apellidos.toUpperCase());
      formData.append("denu_telefono", this.caso.denu_telefono.toUpperCase());
      formData.append("denu_tipo_dir", this.caso.denu_tipo_dir.toUpperCase());
      formData.append("denu_email2", this.caso.denu_email2.toUpperCase());
      formData.append("denu_email", this.caso.denu_email.toUpperCase());
      formData.append("denu_anonimizado_por", this.caso.denu_anonimizado_por.toUpperCase());
      formData.append(
        "denu_organizacion",
        this.caso.denu_organizacion.toUpperCase()
      );
      formData.append("denu_relacion_cd", this.caso.denu_relacion_cd);
      formData.append("denu_consulta", this.caso.denu_consulta.toUpperCase());
      formData.append(
        "denu_tipo_delito",
        this.caso.denu_tipo_delito.toUpperCase()
      );
      formData.append("denu_personas", this.caso.denu_personas.toUpperCase());
      formData.append("denu_departamento", this.caso.denu_departamento);
      formData.append("denu_sede", this.caso.denu_sede);
      formData.append("denu_fuente", this.caso.denu_fuente);

      if (this.tipo === "consultation") {
        this.caso.denu_tipo_denuncia = "consulta";
      } else if (this.tipo === "complaint") {
        if (this.anonymous) {
          this.caso.denu_tipo_denuncia = "anonimo";
        } else {
          this.caso.denu_tipo_denuncia = "nominal";
        }
      }
      formData.append(
        "denu_tipo_denuncia",
        this.caso.denu_tipo_denuncia.toUpperCase()
      );
      this.caso.denu_empresa_cd = this.emp_id;
      this.caso.denu_fuente = this.emp_canal;
      formData.append("denu_empresa_cd", this.caso.denu_empresa_cd);
      formData.append("denu_fuente", this.caso.denu_fuente);

      if (this.tipo === "complaint") {
        if (this.$refs.pond.getFile(0)) {
          formData.append("denu_archivo", this.$refs.pond.getFile(0).file);
        } else {
          formData.append("denu_archivo", "");
        }
      } else {
        formData.append("denu_archivo", "");
      }
      return formData;
    },
    formatDataInv() {
      let formData = new FormData();
      if (this.author && this.author.dein_id) {
        formData.append("dein_id", this.author.dein_id);
      }
      formData.append("dein_texto", this.author.dein_texto.toUpperCase());
      formData.append("dein_usuario_cd", this.id_usu);
      formData.append("dein_denuncia_cd", this.caso.denu_id);

      if (this.$refs.pond) {
        for (let i = 0; i < this.$refs.pond.getFiles().length; i++) {
          if (this.$refs.pond.getFile(i)) {
            formData.append(
              `denu_archivo${i}`,
              this.$refs.pond.getFile(i).file
            );
          } else {
            formData.append(`denu_archivo${i}`, "");
          }
        }
      }

      return formData;
    },
    formatDataClosePhase2() {
      let formData = new FormData();

      formData.append("denu_id", this.caso.denu_id);
      formData.append("denu_final", this.closephase2.denu_final.toUpperCase());
      formData.append(
        "denu_causas",
        this.closephase2.denu_causas.toUpperCase()
      );
      formData.append(
        "denu_fase2_observaciones",
        this.closephase2.denu_fase2_observaciones.toUpperCase()
      );

      formData.append("denu_procedente", this.caso.denu_procedente);

      if (this.$refs.archivoFinal) {
        for (let i = 0; i < this.$refs.archivoFinal.getFiles().length; i++) {
          if (this.$refs.archivoFinal.getFile(i)) {
            formData.append(
              `denu_archivo_final_id${i}`,
              this.$refs.archivoFinal.getFile(i).file
            );
          } else {
            formData.append(`denu_archivo_final_id${i}`, "");
          }
        }
      }

      formData.append("denu_tipo", this.caso.denu_tipo_denuncia);

      return formData;
    },
    verArchivo(item) {
      this.logo = `${this.servidor}api/ver_archivo.asp?denu_id=${this.caso.denu_id}&dear_id=${item.dear_id}`;
    },
    disabledFields() {
      if (this.usu_rol === 0) {
        this.rol = true;
      } else {
        this.rol = false;
      }
    },
    seePhaseAnalysis(direction) {
      this.disableDeci = false;
      if (direction === 0) {
        this.caso.denu_estado = "CREADA";
        this.rol = true;
        this.disableDeci = true;
        this.getFilesDenuFich("FASE2");
        this.getDatasCases();
      } else if (direction === 1) {
        this.caso.denu_estado = "ANALISIS";
        this.getDatasCases();
        if (this.phase4 === true) {
          this.disableDeci = true;
        } else {          
          this.disabledFields();
        }        
      } else if (direction === 2) {
        this.caso.denu_estado = "DECISION";
        this.disableDeci = true;
        this.disabledFields();
        this.getDatasCases();
      }
      this.changePhase = true;
    },
    closeCardPhase() {
      this.changeBreadCrumb(21);
      this.caso = {};
      this.activeCase = false;
      if (this.phase3 === true) {
        this.caso.denu_estado = "ANALISIS";
      } else if (this.phase4 === true) {
        this.caso.denu_estado = "DECISION";
      }
    },
    async saveCasePhase3() {
      this.loading = true;
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=cerrarfase3`,
          this.formatDataPhase3(false)
        );
        if (res.data) {
          this.result = res.data.RESULT;
        }
      } catch (error) {
        console.error(error);
      }
      this.cleanFormCloseCase(3);
    },
    async closeCasePhase3() {
      this.loading = true;
      try {
        const tieneFirma = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=tieneFirma&id=${this.id_usu}`
        );
        if (tieneFirma.data.usu_firma === 1) {
          const sign = await axios.post(`${this.servidor}api/sign/cert.asp`);
        }
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=cerrarfase3`,
          this.formatDataPhase3(true)
        );
        if (res.data) {
          this.result = res.data.RESULT;
          $("#closephase3").modal("hide");
          $("#responseModal").modal("show");
        }
      } catch (error) {
        console.error(error);
      }
      this.cleanFormCloseCase(3);
    },
    formatDataPhase3(close) {
      let formData = new FormData();

      formData.append("denu_id", this.caso.denu_id);
      formData.append("denu_deci_personas", this.txtpeople.toUpperCase());
      formData.append("denu_deci_compliance", this.txtcompliance.toUpperCase());
      formData.append(
        "denu_deci_organizacion",
        this.txtorganization.toUpperCase()
      );
      formData.append(
        "denu_deci_otros_implicados",
        this.txtcompliant.toUpperCase()
      );
      formData.append(
        "denu_deci_comunicacion",
        this.txtcomunication.toUpperCase()
      );
      formData.append("denu_deci_otros", this.txtother.toUpperCase());

      if (this.$refs.filepeople) {
        for (let i = 0; i < this.$refs.filepeople.getFiles().length; i++) {
          if (this.$refs.filepeople.getFile(i)) {
            formData.append(
              `denu_deci_personas_archivo${i}`,
              this.$refs.filepeople.getFile(i).file
            );
          } else {
            formData.append(`denu_deci_personas_archivo${i}`, "");
          }
        }
      }

      if (this.$refs.filecompliance) {
        for (let i = 0; i < this.$refs.filecompliance.getFiles().length; i++) {
          if (this.$refs.filecompliance.getFile(i)) {
            formData.append(
              `denu_deci_compliance_archivo${i}`,
              this.$refs.filecompliance.getFile(i).file
            );
          } else {
            formData.append(`denu_deci_compliance_archivo${i}`, "");
          }
        }
      }

      if (this.$refs.fileorganization) {
        for (
          let i = 0;
          i < this.$refs.fileorganization.getFiles().length;
          i++
        ) {
          if (this.$refs.fileorganization.getFile(i)) {
            formData.append(
              `denu_deci_organizacion_archivo${i}`,
              this.$refs.fileorganization.getFile(i).file
            );
          } else {
            formData.append(`denu_deci_organizacion_archivo${i}`, "");
          }
        }
      }

      if (this.$refs.filecompliant) {
        for (let i = 0; i < this.$refs.filecompliant.getFiles().length; i++) {
          if (this.$refs.filecompliant.getFile(i)) {
            formData.append(
              `denu_deci_otros_implicados_archivo${i}`,
              this.$refs.filecompliant.getFile(i).file
            );
          } else {
            formData.append(`denu_deci_otros_implicados_archivo${i}`, "");
          }
        }
      }

      if (this.$refs.filecomunication) {
        for (
          let i = 0;
          i < this.$refs.filecomunication.getFiles().length;
          i++
        ) {
          if (this.$refs.filecomunication.getFile(i)) {
            formData.append(
              `denu_deci_comunicacion_archivo${i}`,
              this.$refs.filecomunication.getFile(i).file
            );
          } else {
            formData.append(`denu_deci_comunicacion_archivo${i}`, "");
          }
        }
      }

      if (this.$refs.fileother) {
        for (let i = 0; i < this.$refs.fileother.getFiles().length; i++) {
          if (this.$refs.fileother.getFile(i)) {
            formData.append(
              `denu_deci_otros_archivo${i}`,
              this.$refs.fileother.getFile(i).file
            );
          } else {
            formData.append(`denu_deci_otros_archivo${i}`, "");
          }
        }
      }

      formData.append(
        "denu_decision_final",
        this.caso.denu_decision_final.toUpperCase()
      );

      if (close) {
        if (this.txtobservation) {
          formData.append(
            "denu_fase3_observacion",
            this.txtobservation.toUpperCase()
          );
        }
        formData.append("denu_estado", "1");
      } else {
        formData.append("denu_fase3_observacion", "");
        formData.append("denu_estado", "");
      }

      return formData;
    },
    async saveCasePhase4() {
      this.loading = true;
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=cerrarfase4`,
          this.formatDataPhase4(false)
        );
        if (res.data) {
          this.result = res.data.RESULT;
        }
      } catch (error) {
        console.error(error);
      }
      this.cleanFormCloseCase(4);
    },
    async closeCasePhase4() {
      this.loading = true;
      try {
        const tieneFirma = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=tieneFirma&id=${this.id_usu}`
        );
        if (tieneFirma.data.usu_firma === 1) {
          const sign = await axios.post(`${this.servidor}api/sign/cert.asp`);
        }
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=cerrarfase4`,
          this.formatDataPhase4(true)
        );
        if (res.data) {
          this.result = res.data.RESULT;
          $("#closephase4").modal("hide");
          $("#responseModal").modal("show");
        }
      } catch (error) {
        console.error(error);
      }
      this.cleanFormCloseCase(3);
      this.cleanForm();
    },
    formatDataPhase4(close) {
      let formData = new FormData();

      formData.append("denu_id", this.caso.denu_id);
      formData.append(
        "denu_comu_personas",
        this.caso.denu_comu_personas.toUpperCase()
      );
      formData.append(
        "denu_comu_responsable",
        this.caso.denu_comu_responsable.toUpperCase()
      );
      formData.append(
        "denu_comu_organizacion",
        this.caso.denu_comu_organizacion.toUpperCase()
      );
      formData.append(
        "denu_comu_denunciante",
        this.caso.denu_comu_denunciante.toUpperCase()
      );
      formData.append(
        "denu_comu_adminpublica",
        this.caso.denu_comu_adminpublica.toUpperCase()
      );
      formData.append(
        "denu_comu_otras",
        this.caso.denu_comu_otras.toUpperCase()
      );
      formData.append(
        "denu_comu_otras1",
        this.caso.denu_comu_otras1.toUpperCase()
      );
      formData.append(
        "denu_comu_otras2",
        this.caso.denu_comu_otras2.toUpperCase()
      );

      if (this.caso.denu_comu_otra_interesada) {
        formData.append(
          "denu_comu_otras_interesada",
          this.caso.denu_comu_otra_interesada.toUpperCase()
        );
      }

      if (this.caso.denu_comu_otra1_interesada) {
        formData.append(
          "denu_comu_otras1_interesada",
          this.caso.denu_comu_otra1_interesada.toUpperCase()
        );
      }

      if (this.caso.denu_comu_otra2_interesada) {
        formData.append(
          "denu_comu_otras2_interesada",
          this.caso.denu_comu_otra2_interesada.toUpperCase()
        );
      }

      if (this.$refs.cfilepeople) {
        for (let i = 0; i < this.$refs.cfilepeople.getFiles().length; i++) {
          if (this.$refs.cfilepeople.getFile(i)) {
            formData.append(
              `denu_comu_personas_archivo${i}`,
              this.$refs.cfilepeople.getFile(i).file
            );
          } else {
            formData.append(`denu_comu_personas_archivo${i}`, "");
          }
        }
      }

      if (this.$refs.cfilecompliance) {
        for (let i = 0; i < this.$refs.cfilecompliance.getFiles().length; i++) {
          if (this.$refs.cfilecompliance.getFile(i)) {
            formData.append(
              `denu_comu_responsable_archivo${i}`,
              this.$refs.cfilecompliance.getFile(i).file
            );
          } else {
            formData.append(`denu_comu_responsable_archivo${i}`, "");
          }
        }
      }

      if (this.$refs.cfileorganization) {
        for (
          let i = 0;
          i < this.$refs.cfileorganization.getFiles().length;
          i++
        ) {
          if (this.$refs.cfileorganization.getFile(i)) {
            formData.append(
              `denu_comu_organizacion_archivo${i}`,
              this.$refs.cfileorganization.getFile(i).file
            );
          } else {
            formData.append(`denu_comu_organizacion_archivo${i}`, "");
          }
        }
      }

      if (this.$refs.cfilecompliant) {
        for (let i = 0; i < this.$refs.cfilecompliant.getFiles().length; i++) {
          if (this.$refs.cfilecompliant.getFile(i)) {
            formData.append(
              `denu_comu_denunciante_archivo${i}`,
              this.$refs.cfilecompliant.getFile(i).file
            );
          } else {
            formData.append(`denu_comu_denunciante_archivo${i}`, "");
          }
        }
      }

      if (this.$refs.cfilecomunication) {
        for (
          let i = 0;
          i < this.$refs.cfilecomunication.getFiles().length;
          i++
        ) {
          if (this.$refs.cfilecomunication.getFile(i)) {
            formData.append(
              `denu_comu_adminpublica_archivo${i}`,
              this.$refs.cfilecomunication.getFile(i).file
            );
          } else {
            formData.append(`denu_comu_adminpublica_archivo${i}`, "");
          }
        }
      }

      if (this.$refs.cfileother) {
        for (let i = 0; i < this.$refs.cfileother.getFiles().length; i++) {
          if (this.$refs.cfileother.getFile(i)) {
            formData.append(
              `denu_comu_otras_archivo${i}`,
              this.$refs.cfileother.getFile(i).file
            );
          } else {
            formData.append(`denu_comu_otras_archivo${i}`, "");
          }
        }
      }

      if (this.$refs.cfileother1) {
        for (let i = 0; i < this.$refs.cfileother1.getFiles().length; i++) {
          if (this.$refs.cfileother1.getFile(i)) {
            formData.append(
              `denu_comu_otras1_archivo${i}`,
              this.$refs.cfileother1.getFile(i).file
            );
          } else {
            formData.append(`denu_comu_otras1_archivo${i}`, "");
          }
        }
      }

      if (this.$refs.cfileother2) {
        for (let i = 0; i < this.$refs.cfileother2.getFiles().length; i++) {
          if (this.$refs.cfileother2.getFile(i)) {
            formData.append(
              `denu_comu_otras2_archivo${i}`,
              this.$refs.cfileother2.getFile(i).file
            );
          } else {
            formData.append(`denu_comu_otras2_archivo${i}`, "");
          }
        }
      }

      if (this.caso.denu_comu_personas_canal === null) {
        formData.append("denu_comu_personas_canal", "");
      } else {
        formData.append(
          "denu_comu_personas_canal",
          this.caso.denu_comu_personas_canal
        );
      }
      formData.append(
          "denu_comu_personas_email",
          this.caso.denu_comu_personas_email
        );
      if (this.caso.denu_comu_personas_enviar === null) {
        formData.append("denu_comu_personas_enviar", "");
      } else {
        formData.append(
          "denu_comu_personas_enviar",
          this.caso.denu_comu_personas_enviar
        );
      }
      formData.append(
          "denu_comu_responsable_email",
          this.caso.denu_comu_responsable_email
        );
      if (this.caso.denu_comu_responsable_enviar === null) {
        formData.append("denu_comu_responsable_enviar", "");
      } else {
        formData.append(
          "denu_comu_responsable_enviar",
          this.caso.denu_comu_responsable_enviar
        );
      }
      formData.append(
          "denu_comu_organizacion_email",
          this.caso.denu_comu_organizacion_email
        );
      if (this.caso.denu_comu_organizacion_enviar === null) {
        formData.append("denu_comu_organizacion_enviar", "");
      } else {
        formData.append(
          "denu_comu_organizacion_enviar",
          this.caso.denu_comu_organizacion_enviar
        );
      }
      formData.append(
          "denu_comu_denunciante_email",
          this.caso.denu_comu_denunciante_email
        );
      if (this.caso.denu_comu_denunciante_enviar === null) {
        formData.append("denu_comu_denunciante_enviar", "");
      } else {
        formData.append(
          "denu_comu_denunciante_enviar",
          this.caso.denu_comu_denunciante_enviar
        );
      }

formData.append(
          "denu_comu_adminpublica_email",
          this.caso.denu_comu_adminpublica_email
        );
      if (this.caso.denu_comu_adminpublica_enviar === null) {
        formData.append("denu_comu_adminpublica_enviar", "");
      } else {
        formData.append(
          "denu_comu_adminpublica_enviar",
          this.caso.denu_comu_adminpublica_enviar
        );
      }
      formData.append(
          "denu_comu_otras_email",
          this.caso.denu_comu_otras_email
        );
      if (this.caso.denu_comu_otras_enviar === null) {
        formData.append("denu_comu_otras_enviar", "");
      } else {
        formData.append(
          "denu_comu_otras_enviar",
          this.caso.denu_comu_otras_enviar
        );
      }

      if (this.caso.denu_comu_responsable_canal === null) {
        formData.append("denu_comu_responsable_canal", "");
      } else {
        formData.append(
          "denu_comu_responsable_canal",
          this.caso.denu_comu_responsable_canal
        );
      }

      if (this.caso.denu_comu_organizacion_canal === null) {
        formData.append("denu_comu_organizacion_canal", "");
      } else {
        formData.append(
          "denu_comu_organizacion_canal",
          this.caso.denu_comu_organizacion_canal
        );
      }

      if (this.caso.denu_comu_denunciante_canal === null) {
        formData.append("denu_comu_denunciante_canal", "");
      } else {
        formData.append(
          "denu_comu_denunciante_canal",
          this.caso.denu_comu_denunciante_canal
        );
      }

      if (this.caso.denu_comu_adminpublica_canal === null) {
        formData.append("denu_comu_adminpublica_canal", "");
      } else {
        formData.append(
          "denu_comu_adminpublica_canal",
          this.caso.denu_comu_adminpublica_canal
        );
      }

      if (this.caso.denu_comu_otras_canal === null) {
        formData.append("denu_comu_otras_canal", "");
      } else {
        formData.append(
          "denu_comu_otras_canal",
          this.caso.denu_comu_otras_canal
        );
      }

      if (this.caso.denu_comu_otras1_canal === null) {
        formData.append("denu_comu_otras1_canal", "");
      } else {
        formData.append(
          "denu_comu_otras1_canal",
          this.caso.denu_comu_otras1_canal
        );
      }

      if (this.caso.denu_comu_otras2_canal === null) {
        formData.append("denu_comu_otras2_canal", "");
      } else {
        formData.append(
          "denu_comu_otras2_canal",
          this.caso.denu_comu_otras2_canal
        );
      }

      if (close) {
        if (this.caso.denu_fase4_observacion) {
          formData.append(
            "denu_fase4_observacion",
            this.caso.denu_fase4_observacion.toUpperCase()
          );
          formData.append("denu_estado", "1");
        } else {
          formData.append("denu_fase4_observacion", "");
        }
        formData.append("denu_estado", "1");
      } else {
        formData.append("denu_fase4_observacion", "");
        formData.append("denu_estado", "");
      }
      formData.append("denu_resolucion_usuario", this.resolucionUsuario);

      return formData;
    },
    getId(id, phase = 0) {
      this.idDelete = id;
      this.phaseDelete = phase;
    },
    async confirmDeleteFiles() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=borrarArchivos&id=${this.idDelete}`
        );

        if (res) {
          this.result = res.data.RESULT;
        }
        this.getFilesDenuFich("INVES");
        if (this.phaseDelete === 0) {
          $("#investigacion").modal("show");
        } else if ((this.phaseDelete = 3)) {
          this.getFilesFase3();
        } else if ((this.phaseDelete = 4)) {
          this.getFilesFase4();
        }
      } catch {
        if (this.phaseDelete === 0) {
          $("#investigacion").modal("show");
        } else if (this.phaseDelete === 3) {
          this.getFilesFase3();
        } else if (this.phaseDelete === 4) {
          this.getFilesFase4();
        }
      }
    },
    async getFilesFase3() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=listarArchivosFase3&id=${this.caso.denu_id}`
        );
        if (res.data) {
          this.filesDenuFichFase3 = res.data.data;

          if (this.filesDenuFichFase3) {
            this.filesDeciPeople = this.filesDenuFichFase3.filter(
              (file) => file.defi_tipo === "DEPER"
            );
          }

          if (this.filesDenuFichFase3) {
            this.filesDeciCompl = this.filesDenuFichFase3.filter(
              (file) => file.defi_tipo === "DECOM"
            );
          }

          if (this.filesDenuFichFase3) {
            this.filesDeciOrg = this.filesDenuFichFase3.filter(
              (file) => file.defi_tipo === "DEORG"
            );
          }

          if (this.filesDenuFichFase3) {
            this.filesDeciOim = this.filesDenuFichFase3.filter(
              (file) => file.defi_tipo === "DEOIM"
            );
          }

          if (this.filesDenuFichFase3) {
            this.filesDeciComu = this.filesDenuFichFase3.filter(
              (file) => file.defi_tipo === "DECUM"
            );
          }

          if (this.filesDenuFichFase3) {
            this.filesDeciOtros = this.filesDenuFichFase3.filter(
              (file) => file.defi_tipo === "DEOTR"
            );
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getFilesFase4() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=listarArchivosFase4&id=${this.caso.denu_id}`
        );

        if (res.data) {
          this.filesDenuFichFase4 = res.data.data;

          if (this.filesDenuFichFase4) {
            this.filesComuPeo = this.filesDenuFichFase4.filter(
              (file) => file.defi_tipo === "COPER"
            );
          }

          if (this.filesDenuFichFase4) {
            this.filesComuRes = this.filesDenuFichFase4.filter(
              (file) => file.defi_tipo === "CORES"
            );
          }

          if (this.filesDenuFichFase4) {
            this.filesComuOrg = this.filesDenuFichFase4.filter(
              (file) => file.defi_tipo === "COORG"
            );
          }

          if (this.filesDenuFichFase4) {
            this.filesComuDen = this.filesDenuFichFase4.filter(
              (file) => file.defi_tipo === "CODEN"
            );
          }

          if (this.filesDenuFichFase4) {
            this.filesComuApb = this.filesDenuFichFase4.filter(
              (file) => file.defi_tipo === "COAPB"
            );
          }

          if (this.filesDenuFichFase4) {
            this.filesComuOtros = this.filesDenuFichFase4.filter(
              (file) => file.defi_tipo === "COOTR"
            );
          }

          if (this.filesDenuFichFase4) {
            this.filesComuOtros1 = this.filesDenuFichFase4.filter(
              (file) => file.defi_tipo === "COOT1"
            );
          }

          if (this.filesDenuFichFase4) {
            this.filesComuOtros2 = this.filesDenuFichFase4.filter(
              (file) => file.defi_tipo === "COOT2"
            );
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getColorType() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_emp.asp?ac=color&id=${this.emp_id}`
        );
        const color = res.data.emp_color;
        if (color === 0) {
          this.changeColorCompanyOrange();
        } else if (color === 1) {
          this.changeColorCompanyBlue();
        } else if (color === 2) {
          this.changeColorCompanyGreen();
        } else if (color === 3) {
          this.changeColorCompanyRed();
        } else if (color === 4) {
          this.changeColorCompany4();
        } else if (color === 5) {
          this.changeColorCompan5();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async exportCase() {
//      const doc = new jsPDF("p", "pt", "A4");

      const res = await axios.post(
        `${this.servidor}api/export.asp?id=${this.caso.denu_id}`
      );

//      doc.html(res.data, {
//        x: 10,
//        y: 10,
//        callback: function (doc) {
//          doc.save();
//        },
//      });
var mapForm = document.createElement("form");
    mapForm.target = "_blank";    
    mapForm.method = "POST";
    mapForm.action = `${this.servidor}api/exportacion.asp`;

    var mapInput = document.createElement("input");
    mapInput.type = "text";
    mapInput.name = "id";
    mapInput.value = `${this.caso.denu_id}`;
    mapForm.appendChild(mapInput);

    document.body.appendChild(mapForm);

    mapForm.submit();
      this.tableToExport = false;
    },
    changeActiveButtonDeci() {
      if (this.people) {
        this.bpeople = this.colorCompany;
      } else {
        this.bpeople = "#696768";
      }
      if (this.compliance) {
        this.bcompliance = this.colorCompany;
      } else {
        this.bcompliance = "#696768";
      }
      if (this.organization) {
        this.borganization = this.colorCompany;
      } else {
        this.borganization = "#696768";
      }
      if (this.compliant) {
        this.bcompliant = this.colorCompany;
      } else {
        this.bcompliant = "#696768";
      }
      if (this.comunication) {
        this.bcomunication = this.colorCompany;
      } else {
        this.bcomunication = "#696768";
      }
      if (this.other) {
        this.bother = this.colorCompany;
      } else {
        this.bother = "#696768";
      }
    },
    changeActiveButtonComu() {
      if (this.cpeople) {
        this.bcpeople = this.colorCompany;
      } else {
        this.bcpeople = "#696768";
      }
      if (this.cresponsible) {
        this.bcresponsible = this.colorCompany;
      } else {
        this.bcresponsible = "#696768";
      }
      if (this.corganization) {
        this.bcorganization = this.colorCompany;
      } else {
        this.bcorganization = "#696768";
      }
      if (this.ccompliant) {
        this.bccompliant = this.colorCompany;
      } else {
        this.bccompliant = "#696768";
      }
      if (this.cadminpublica) {
        this.bcadminpublica = this.colorCompany;
      } else {
        this.bcadminpublica = "#696768";
      }
      if (this.cother) {
        this.bcother = this.colorCompany;
      } else {
        this.bcother = "#696768";
      }
      if (this.cother1) {
        this.bcother1 = this.colorCompany;
      } else {
        this.bcother1 = "#696768";
      }
      if (this.cother2) {
        this.bcother2 = this.colorCompany;
      } else {
        this.bcother2 = "#696768";
      }
    },
    filterDates(fecha) {
      return moment(fecha).format("MM/DD/YYYY, h:mm:ss");
    },
    async sendMessage() {
      this.loading = true;
      let message = {
        deme_texto: this.messageBox.toUpperCase(),
        denu_id: this.caso.denu_id,
      };
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=denunciante&ac=grabarMensaje`,
          message
        );
        if (res && res.data) {
          this.result = res.data.RESULT;
          this.loading = false;
        }
        this.seeMessages(this.caso.denu_id);
        this.cleanForm();
      } catch (error) {
        console.error(error);
      }
    },
    cleanForm() {
      this.messageBox = "";
      this.resolucionUsuario = "";
    }
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

select {
  color: #898f96;
  height: 35pt;
  margin-bottom: 40px;
}

#cardForm {
  margin-top: 40px;
  padding: 25pt;
  display: block;
  margin-bottom: 40px;
}

.close-case {
  color: #fff;
  background-color: #666666;
  width: 100%;
}

.close-case-phase3 {
  color: #fff;
  background-color: #666666;
  /* border-color: #666666; */
  width: 49.9%;
}

.close-form {
  float: right;
  font-size: 17pt;
  margin-top: -28pt;
}

.export-case {
  margin-top: -30pt;
  margin-right: 70pt;
}

.see-phase2 {
  margin-top: -30pt;
}

.btn-header {
  float: none;
  background-color: #696768;
  color: #fff;
  margin-top: 20pt;
  margin-bottom: 20pt;
  margin-right: 5pt;
}

.textboxphase3 {
  margin-bottom: 5pt;
}

.active {
  background-color: #c0c0c0;
}

.nodeci {
  margin-right: 221pt;
}

.sideci {
  margin-right: 322pt;
}

.nocomu {
  margin-right: 188pt;
}

.sicomu {
  margin-right: 322pt;
}

/* button:focus {
  outline: none;
  box-shadow: 0px 0px 0px 3px rgb(255 128 0 / 20%);
} */

.header-button {
  width: 100pt;
}
</style>