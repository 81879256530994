<template>
  <div class="wrapper">
    <menu-ver type="company" name="Company" />
    <div class="container m-3">
      <div style="margin-top: 100pt"></div>
      <div class="px-4 py-3">
        <div class="mb-3">
          <label class="form-label">{{
            $t(`configuration.advise`) + " *"
          }}</label>
        </div>
        <div class="mb-3">
          <label class="form-label">{{
            $t(`configuration.footer`) + " *"
          }}</label>
          <textarea
            class="form-control"
            rows="7"
            v-model="emp_smtp.emp_smtp_pie_legal"
            style="text-transform: uppercase"
          />
        </div>
        <div class="mb-3">
          <label class="form-label">{{
            $t(`configuration.email`) + " *"
          }}</label>
          <input
            style="text-transform: uppercase"
            type="email"
            class="form-control"
            v-model.trim="emp_smtp.emp_smtp_de"
          />
        </div>
        <div class="mb-3">
          <label class="form-label">{{
            $t(`configuration.user`) + " *"
          }}</label>
          <input
            style="text-transform: uppercase"
            type="text"
            class="form-control"
            v-model.trim="emp_smtp.emp_smtp_usuario"
            maxlength="50"
          />
        </div>
        <div class="mb-3">
          <label class="form-label">{{
            $t(`configuration.password`) + " *"
          }}</label>
          <input
            v-if="visPas3"
            type="password"
            class="form-control"
            v-model.trim="emp_smtp.emp_smtp_clave"
            maxlength="20"
          />
          <input
            v-else
            type="text"
            class="form-control"
            v-model.trim="emp_smtp.emp_smtp_clave"
            maxlength="20"
          />
          <i
            v-if="!visPas3"
            @click="visPas3 = true"
            class="fas fa-eye-slash field-icon"
          ></i>
          <i v-else @click="visPas3 = false" class="fas fa-eye field-icon"></i>
        </div>
        <div class="mb-3">
          <label class="form-label">{{
            $t(`configuration.server`) + " *"
          }}</label>
          <input
            type="text"
            class="form-control"
            v-model.trim="emp_smtp.emp_smtp_servidor"
          />
        </div>
        <div class="mb-3">
          <label class="form-label">{{
            $t(`configuration.port`) + " *"
          }}</label>
          <input
            type="text"
            class="form-control"
            v-model.trim="emp_smtp.emp_smtp_puerto"
          />
        </div>
        <div class="mb-3">
          <label class="form-label">{{ $t(`configuration.security`) }}</label>
          <select
            class="form-select inputForm"
            aria-label="Elija una opción"
            v-model.trim="emp_smtp.emp_smtp_seguridad"
          >
            <option value="SINSEGURIDAD">
              {{ $t(`configuration.nosec`) }}
            </option>
            <option value="TLS">TLS</option>
            <option value="SSL">SSL</option>
          </select>
        </div>
        <div class="mb-3">
          <label class="form-label">{{
            $t(`mCompany.users.password`) + " *"
          }}</label>
          <input
            v-if="visPas"
            type="password"
            class="form-control"
            v-model.trim="emp_smtp.clave"
            maxlength="20"
          />
          <input
            v-else
            type="text"
            class="form-control"
            v-model.trim="emp_smtp.clave"
            maxlength="20"
          />
          <i
            v-if="!visPas"
            @click="visPas = true"
            class="fas fa-eye-slash field-icon"
          ></i>
          <i v-else @click="visPas = false" class="fas fa-eye field-icon"></i>
        </div>
        <div class="mb-3">
          <label class="form-label">{{
            $t(`configuration.newPassword`) + " *"
          }}</label>
          <input
            v-if="visPas2"
            type="password"
            class="form-control"
            v-model.trim="emp_smtp.nueva_clave"
            maxlength="20"
          />
          <input
            v-else
            type="text"
            class="form-control"
            v-model.trim="emp_smtp.nueva_clave"
            maxlength="20"
          />
          <i
            v-if="!visPas2"
            @click="visPas2 = true"
            class="fas fa-eye-slash field-icon"
          ></i>
          <i v-else @click="visPas2 = false" class="fas fa-eye field-icon"></i>
        </div>
        <div class="mb-3">
          <label class="form-label">{{
            $t(`configuration.pprivacity`) + " *"
          }}</label>
          <input
            style="text-transform: uppercase"
            type="text"
            class="form-control"
            v-model.trim="emp_smtp.emp_politica"
          />
        </div>
        <span
          v-if="error && messageError === ''"
          class="text-danger"
          style="float: right; font-size: 12px"
          >{{ $t("errors.fieldOb") }}</span
        >
        <span
          v-else
          class="text-danger"
          style="float: right; font-size: 12px"
          >{{ messageError }}</span
        >
        <button
          class="btn button-save"
          @click="postConfiguration()"
          :style="{ backgroundColor: colorCompany }"
        >
          {{ $t("configuration.save") }}
        </button>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="responseModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("errors.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3" style="text-align: center">
            <div>
              <div class="modal-body" v-if="result === '0'">
                {{ $t("errors.0") }}
              </div>
              <div class="modal-body" v-else-if="result === '1'">
                {{ $t("errors.1") }}
              </div>
              <div class="modal-body" v-else-if="result === '2'">
                {{ $t("errors.2") }}
              </div>
              <div class="modal-body" v-else-if="result === '3'">
                {{ $t("errors.3") }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn button btn-return-result" data-bs-dismiss="modal">
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import MenuVer from "../../../components/MenuVer";
import axios from "axios";

export default {
  data() {
    return {
      emp_smtp: {},
      result: "",
      error: false,
      visPas3: true,
      visPas2: true,
      visPas: true,
      messageError: "",
    };
  },
  components: {
    MenuVer,
  },
  created() {
    this.changeBreadCrumb(32);
    this.bigDrop();
    this.getConfiguration();
  },
  computed: {
    ...mapState(["id_emp", "colorCompany", "servidor"]),
  },
  methods: {
    ...mapMutations(["changeBreadCrumb", "bigDrop"]),
    async getConfiguration() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_emp.asp?tp=config&ac=mostrar`
        );
        
        if (res.data) {
          this.emp_smtp = res.data.data[0];
        }
        
      } catch (error) {
        console.error(error);
      }
    },
    async postConfiguration() {
      this.result = "";
      if (this.validateForm()) {
        try {
          this.emp_smtp.emp_id = this.id_emp;
          this.emp_smtp.emp_smtp_pie_legal =
            this.emp_smtp.emp_smtp_pie_legal.toUpperCase();
          
          const res = await axios.post(
            `${this.servidor}api/op_emp.asp?tp=config&ac=guardar`,
            this.emp_smtp
          );
          if (res.data) {
            this.result = res.data.RESULT;
          }
          
          $("#responseModal").modal("show");
        } catch (error) {
          console.error(error);
        }
      }
    },
    validateForm() {
      this.messageError = "";
      this.error = false;
      if (
        this.emp_smtp.emp_smtp_pie_legal === null &&
        this.emp_smtp.emp_smtp_pie_legal.length <= 0
      ) {
        this.error = true;
        return false;
      }
      if (
        this.emp_smtp.emp_smtp_de === null &&
        this.emp_smtp.emp_smtp_de.length <= 0
      ) {
        this.error = true;
        return false;
      }
      if (
        this.emp_smtp.emp_smtp_usuario === null &&
        this.emp_smtp.emp_smtp_usuario.length <= 0
      ) {
        this.error = true;
        return false;
      }
      if (
        this.emp_smtp.emp_smtp_clave === null &&
        this.emp_smtp.emp_smtp_clave.length <= 0
      ) {
        this.error = true;
        return false;
      }
      if (
        this.emp_smtp.emp_smtp_servidor === null &&
        this.emp_smtp.emp_smtp_servidor.length <= 0
      ) {
        this.error = true;
        return false;
      }
      if (
        this.emp_smtp.emp_smtp_puerto === null &&
        this.emp_smtp.emp_smtp_puerto.length <= 0
      ) {
        this.error = true;
        return false;
      }
      if (
        this.emp_smtp.emp_smtp_seguridad === null &&
        this.emp_smtp.emp_smtp_seguridad.length <= 0
      ) {
        this.error = true;
        return false;
      }
      if (!this.validatePassword()) {
        this.error = true;
        this.messageError = this.$t("configuration.messageError2");
        return false;
      }
      this.error = false;
      return true;
    },
    validatePassword() {
      if (this.emp_smtp.nueva_clave) {
        if (this.emp_smtp.nueva_clave.length >= 8) {
          let mayuscula = false;
          let minuscula = false;
          let numero = false;
          let caracter_raro = false;

          for (var i = 0; i < this.emp_smtp.nueva_clave.length; i++) {
            if (
              this.emp_smtp.nueva_clave.charCodeAt(i) >= 65 &&
              this.emp_smtp.nueva_clave.charCodeAt(i) <= 90
            ) {
              mayuscula = true;
            } else if (
              this.emp_smtp.nueva_clave.charCodeAt(i) >= 97 &&
              this.emp_smtp.nueva_clave.charCodeAt(i) <= 122
            ) {
              minuscula = true;
            } else if (
              this.emp_smtp.nueva_clave.charCodeAt(i) >= 48 &&
              this.emp_smtp.nueva_clave.charCodeAt(i) <= 57
            ) {
              numero = true;
            } else {
              caracter_raro = true;
            }
          }
          if (
            mayuscula == true &&
            minuscula == true &&
            caracter_raro == true &&
            numero == true
          ) {
            return true;
          }
        }
        this.messageError = this.$t("configuration.messageError2");
        this.formError = true;
        return false;
      }
    },
  },
};
</script>

<style scoped>
.button-save {
  color: #fff;
  background-color: #666666;
  width: 100%;
  border-radius: 20pt;
  margin-bottom: 20pt;
}
select {
  height: 35pt;
  margin-bottom: 40px;
}
.field-icon {
  float: right;
  margin-top: -69px;
  position: relative;
  z-index: 2;
  left: -11px;
  cursor: pointer;
}
</style>