<template>
  <div class="wrapper">
    <menu-ver type="user" name="User" :id_emp="emp_id" />
    <div class="container m-3">
      <div class="table-header"></div>
      <div class="container" v-if="activeCase">
        <div>
          <button
            type="button"
            class="btn-close close-form"
            @click="closeCardPhase()"
          ></button>
          <button
            type="button"
            class="btn button buttonEdit export-case"
            @click="exportCase"
            :style="{ backgroundColor: colorCompany }"
          >
            <i
              class="fas fa-file-export"
              :data-title="$t(`mUser.ocases.exportCase`)"
            ></i>
          </button>
          <button
            type="button"
            class="btn button buttonEdit see-phase2 header-button"
            @click="seePhaseAnalysis(3)"
            :style="{ backgroundColor: colorCompany }"
            style="margin-right: 450pt"
          >
            {{ $t("mUser.chistory.seecomunication") }}
          </button>

          <button
            type="button"
            class="btn button buttonEdit see-phase2 header-button"
            @click="seePhaseAnalysis(0)"
            :style="{ backgroundColor: colorCompany }"
            :class="{
              nodeci: caso.denu_tipo_denuncia === 'CONSULTA',
              sideci: caso.denu_tipo_denuncia !== 'CONSULTA',
            }"
          >
            <!-- style="margin-right: 306pt" -->
            {{ $t("mUser.ocases.seephase") }}
          </button>
          <button
            type="button"
            class="btn button buttonEdit see-phase2 header-button"
            @click="seePhaseAnalysis(1)"
            style="margin-right: 221pt"
            v-if="caso.denu_tipo_denuncia != 'CONSULTA'"
            :style="{ backgroundColor: colorCompany }"
          >
            {{ $t("mUser.ocases.phase3.seephase") }}
          </button>
          <button
            type="button"
            class="btn button buttonEdit see-phase2 header-button"
            @click="seePhaseAnalysis(2)"
            :style="{ backgroundColor: colorCompany }"
            style="margin-right: 120pt"
          >
            {{ $t("mUser.ocases.phase4.seephase") }}
          </button>
        </div>
        <div>
          <h3>{{ $t(`mUser.chistory.case`) }} - {{ caso.denu_id.substring(0,10) }}</h3>
          <h4 style="font-size: 14pt">
            {{ rute }}
            <br><br>{{ $t(`mUser.ocases.phase4.ultentrada`) }} - {{ caso.denu_ultimo_acceso }}
          </h4>
        </div>

        <div v-if="caso.denu_estado === 'CREADA'">
          <div
            class="card"
            id="cardForm"
            v-if="caso.denu_tipo_denuncia === 'CONSULTA'"
          >
            <div class="row form">
              <div class="col">
                <label class="form-label">{{
                  $t(`forms.consultation.name`)
                }}</label>
                <input
                  v-model.trim="caso.denu_nombre"
                  type="text"
                  class="form-control"
                  id="nombre"
                  :placeholder="$t(`forms.consultation.name`)"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.consultation.phone`)
                }}</label>
                <input
                  v-model.trim="caso.denu_telefono"
                  type="phone"
                  class="form-control"
                  id="telefono"
                  :placeholder="$t(`forms.consultation.phone`)"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.consultation.otromedia`)
                }}</label>
                <input
                  v-model.trim="caso.denu_tipo_dir"
                  type="phone"
                  class="form-control"
                  id="tipo_dir"
                  disabled
                />
              </div>
              <div class="col">
                <label class="form-label">{{
                  $t(`forms.consultation.surname`)
                }}</label>
                <input
                  v-model.trim="caso.denu_apellidos"
                  type="text"
                  class="form-control"
                  id="apellido"
                  :placeholder="$t(`forms.consultation.surname`)"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.consultation.email`)
                }}</label>
                <input
                  v-model.trim="caso.denu_email"
                  type="email"
                  class="form-control"
                  id="email"
                  :placeholder="$t(`forms.consultation.email`)"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.consultation.mediocom`)
                }}</label>
                <input
                  v-model.trim="caso.denu_email2"
                  type="text"
                  class="form-control"
                  id="email2"
                  disabled
                />
              </div>
            </div>
            <div class="row form">
              <div class="col">
                <label class="form-label">{{
                  $t(`forms.consultation.relationship`)
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  :placeholder="$t(`forms.consultation.relationship`)"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.consultation.organization`)
                }}</label>
                <input
                  v-model.trim="caso.denu_organizacion"
                  type="text"
                  class="form-control"
                  id="organizacion"
                  :placeholder="$t(`forms.consultation.organization`)"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.comp.involved`)
                }}</label>
                <input
                  v-model.trim="caso.denu_personas"
                  type="text"
                  class="form-control"
                  id="organizacion"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.consultation.nature`)
                }}</label>
                <select
                  class="form-select inputForm"
                  aria-label="Elija una opción"
                  v-model.trim="caso.denu_naturaleza_cd"
                  disabled
                >
                  <option
                    v-for="item of natures"
                    :key="item.natu_id"
                    :value="item.natu_id"
                  >
                    {{ item.natu_nombre }}
                  </option>
                </select>
              </div>
              <div class="col">
                <label class="form-label">{{
                  $t(`forms.consultation.message`)
                }}</label>
                <textarea
                  v-model.trim="caso.denu_consulta"
                  class="form-control"
                  :placeholder="$t(`forms.consultation.message`)"
                  rows="9"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.consultation.anompo`)
                }}</label>
                <textarea
                  v-model.trim="caso.denu_anonimizado_por"
                  class="form-control"
                  rows="3"
                  disabled
                />                
              </div>
            </div>
          </div>

          <div class="card" id="cardForm" v-else>
            <div class="row form" v-if="caso.denu_tipo_denuncia === 'NOMINAL'">
              <div class="col">
                <label class="form-label">{{
                  $t(`forms.consultation.name`)
                }}</label>
                <input
                  v-model.trim="caso.denu_nombre"
                  type="text"
                  class="form-control"
                  id="nombre"
                  :placeholder="$t(`forms.consultation.name`)"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.consultation.phone`)
                }}</label>
                <input
                  v-model.trim="caso.denu_telefono"
                  type="phone"
                  class="form-control"
                  id="telefono"
                  :placeholder="$t(`forms.consultation.phone`)"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.consultation.otromedia`)
                }}</label>
                <input
                  v-model.trim="caso.denu_tipo_dir"
                  type="phone"
                  class="form-control"
                  id="tipo_dir"
                  disabled
                />
              </div>
              <div class="col">
                <label class="form-label">{{
                  $t(`forms.consultation.surname`)
                }}</label>
                <input
                  v-model.trim="caso.denu_apellidos"
                  type="text"
                  class="form-control"
                  id="apellido"
                  :placeholder="$t(`forms.consultation.surname`)"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.consultation.email`)
                }}</label>
                <input
                  v-model.trim="caso.denu_email"
                  type="email"
                  class="form-control"
                  id="email"
                  :placeholder="$t(`forms.consultation.email`)"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.consultation.mediocom`)
                }}</label>
                <input
                  v-model.trim="caso.denu_email2"
                  type="text"
                  class="form-control"
                  id="email2"
                  disabled
                />                
              </div>
            </div>
            <div class="row form">
              <div class="col">
                <label class="form-label">{{
                  $t(`forms.consultation.relationship`)
                }}</label>
                <input
                  v-model.trim="rela_name"
                  type="text"
                  class="form-control"
                  :placeholder="$t(`forms.consultation.relationship`)"
                  disabled
                />
                <label
                  class="form-label"
                  v-if="caso.denu_tipo_denuncia === 'NOMINAL'"
                  >{{ $t(`forms.consultation.organization`) }}</label
                >
                <input
                  v-if="caso.denu_tipo_denuncia === 'NOMINAL'"
                  v-model.trim="caso.denu_organizacion"
                  type="text"
                  class="form-control"
                  id="organizacion"
                  :placeholder="$t(`forms.consultation.organization`)"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.comp.involved`)
                }}</label>
                <input
                  v-model.trim="caso.denu_personas"
                  type="text"
                  class="form-control"
                  id="organizacion"
                  disabled
                />                
                <label class="form-label">{{
                  $t(`forms.consultation.nature`)
                }}</label>
                <select
                  class="form-select inputForm"
                  aria-label="Elija una opción"
                  v-model.trim="caso.denu_naturaleza_cd"
                  disabled
                >
                  <option
                    v-for="item of natures"
                    :key="item.natu_id"
                    :value="item.natu_id"
                  >
                    {{ item.natu_nombre }}
                  </option>
                </select>
              </div>
              <div class="col">
                <label class="form-label">{{
                  $t(`forms.consultation.message`)
                }}</label>
                <textarea
                  v-model.trim="caso.denu_consulta"
                  class="form-control"
                  :placeholder="$t(`forms.consultation.message`)"
                  rows="9"
                  disabled
                />
                <label class="form-label">{{
                  $t(`forms.consultation.anompo`)
                }}</label>
                <textarea
                  v-model.trim="caso.denu_anonimizado_por"
                  class="form-control"
                  rows="3"
                  disabled
                />                  
              </div>
            </div>
            
            <div class="row form">
              <div class="col">
                <label
                  class="form-check-label"
                  for="flexCheckDefault"
                  style="margin-top: 5px; margin-right: 5px"
                >
                  {{ $t(`mUser.ocases.isCrime`) }}
                </label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="isCrime"
                  id="flexCheckDefault"
                  style="
                    height: 39px;
                    width: 38px;
                    margin-top: -4px;
                    margin-right: 5px;
                  "
                  disabled
                />
              </div>
              <div class="col">
                <label v-if="isCrime" class="form-label">{{
                  $t(`mUser.ocases.crimes`)
                }}</label>
                <textarea
                  v-model.trim="caso.denu_tipo_delito"
                  class="form-control"
                  :placeholder="$t(`mUser.ocases.crimes`)"
                  rows="2"
                  v-if="isCrime"
                  disabled
                  maxlength="255"
                />
              </div>
            </div>
            <div class="row form">
              <div class="col">
                <label class="form-label">{{ $t(`forms.comp.when`) }}</label>
                <input
                  v-model.trim="caso.denu_cuando"
                  type="text"
                  class="form-control"
                  disabled
                />
              </div>
              <div class="col">
                <label class="form-label">{{
                  $t(`forms.comp.reiterativo`)
                }}</label>
                <input
                  v-model.trim="caso.denu_reiterativo"
                  type="text"
                  class="form-control"
                  disabled
                />
              </div>
            </div>
            <!-- <div class="row form">
              <div class="col">
                <label
                  class="form-check-label"
                  for="flexCheckDefault"
                  style="margin-top: 5px; margin-right: 5px"
                >
                  {{ $t(`mUser.ocases.relevant`) }}
                </label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="caso.denu_pertinente"
                  id="flexCheckDefault"
                  style="
                    height: 39px;
                    width: 38px;
                    margin-top: -4px;
                    margin-right: 5px;
                  "
                  disabled
                />
              </div>
              <div class="col"></div>
            </div> -->
          </div>

          <h3>
            {{ $t(`mUser.ocases.uploadFile`) }}
          </h3>
          <div class="card" id="cardForm">
            <table class="table" id="tableFile">
              <thead>
                <tr>
                  <th scope="col">{{ $t("mUser.ocases.files.date") }}</th>
                  <th scope="col">{{ $t("mUser.ocases.files.name") }}</th>                  
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in files" :key="item.dear_id">
                  <td>{{ item.defi_fecha }}</td>
                  <td>{{ item.defi_nombre }}</td>                  
                  <td>
                    <a
                      class="btn button buttonEdit"
                      target="_blank"
                      :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${caso.denu_id}&dear_id=${item.defi_id}`"
                      :style="{ backgroundColor: colorCompany }"
                    >
                      <i class="fas fa-download"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3>
            {{ $t(`mUser.ocases.investigation`) }}
          </h3>
          <div class="card" id="cardForm">
            <table class="table" id="tableInv">
              <thead>
                <tr>
                  <th scope="col">{{ $t("mUser.ocases.authors.date") }}</th>
                  <th scope="col">{{ $t("mUser.ocases.authors.name") }}</th>                  
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in authors" :key="item.caso_id">
                  <td>{{ item.dein_fecha }}</td>
                  <td>{{ item.usu_nombre }}</td>                  
                  <td>
                    <button
                      class="btn button buttonEdit"
                      @click="editInv(item)"
                      style="margin-left: 5px"
                      data-bs-toggle="modal"
                      data-bs-target="#investigacion"
                      :style="{ backgroundColor: colorCompany }"
                    >
                      <i class="fas fa-info-circle"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <div class="mb-3 mt-4">
              <label class="form-label">{{
                $t(`mUser.ocases.phase3.conclusionPhase2`)
              }}</label>
              <textarea
                class="form-control textboxphase3"
                rows="7"
                disabled
                v-model="caso.denu_final"
                style="text-transform: uppercase"
              />
            </div>
            <div class="mb-3 mt-4">
              <label class="form-label">{{
                $t(`mUser.ocases.phase3.cause`)
              }}</label>
              <textarea
                class="form-control textboxphase3"
                rows="7"
                disabled
                v-model="caso.denu_causas"
                style="text-transform: uppercase"
              />
            </div>
            <div
              class="mb-3 mt-4"
              v-if="
                caso.denu_fase2_observacion &&
                caso.denu_fase2_observacion.length > 0
              "
            >
              <label class="form-label">{{
                $t(`mUser.ocases.phase3.observationphase2`)
              }}</label>
              <textarea
                class="form-control textboxphase3"
                rows="7"
                disabled
                v-model="caso.denu_fase2_observacion"
                style="text-transform: uppercase"
              />
            </div>
            <p v-if="caso.denu_procedente === 1">
              {{ $t(`mUser.ocases.phase3.relevant`) }}
            </p>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesDenuFichFase2"
              :key="item.defi_id"
            >
              <span style="float: right; width: 100%"
                >{{ item.defi_nombre }}
                <a
                  class="btn button buttonEdit"
                  :style="{ backgroundColor: colorCompany }"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${caso.denu_id}&defi_id=${item.defi_id}`"
                  style="
                    margin-right: 5pt;
                    margin-left: 5pt;
                    margin-top: -5pt;
                    margin-bottom: 10pt;
                  "
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
          </div>
        </div>

        <div v-if="caso.denu_estado === 'ANALISIS'">
          <div class="mb-3 card p-3" id="cardForm">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase3.final`) }}
            </label>
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="caso.denu_decision_final"
              disabled
              style="text-transform: uppercase"
            />
          </div>
          <div class="mb-3 card p-3" id="cardForm" v-if="people">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase3.people`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesDeciPeople"
              :key="item.defi_id"
              style="height: 22pt"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="caso.denu_deci_personas"
              disabled
              style="text-transform: uppercase"
            />
          </div>
          <div class="card" id="cardForm" v-if="compliance">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase3.compliance`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesDeciCompl"
              :key="item.defi_id"
              style="height: 22pt"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="caso.denu_deci_compliance"
              disabled
              style="text-transform: uppercase"
            />
          </div>
          <div class="card" id="cardForm" v-if="organization">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase3.organization`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesDeciOrg"
              :key="item.defi_id"
              style="height: 22pt"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="caso.denu_deci_organizacion"
              disabled
              style="text-transform: uppercase"
            />
          </div>
          <div class="card" id="cardForm" v-if="compliant">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase3.compliant`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesDeciOim"
              :key="item.defi_id"
              style="height: 22pt"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="caso.denu_deci_otras_implicadas"
              disabled
              style="text-transform: uppercase"
            />
          </div>
          <div class="card" id="cardForm" v-if="comunication">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase3.comunication`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesDeciComu"
              :key="item.defi_id"
              style="height: 22pt"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="caso.denu_deci_comunicacion"
              disabled
              style="text-transform: uppercase"
            />
          </div>
          <div class="card" id="cardForm" v-if="other">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase3.other`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesDeciOtros"
              :key="item.defi_id"
              style="height: 22pt"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="caso.denu_deci_otras"
              disabled
              style="text-transform: uppercase"
            />
          </div>
          <div
            class="mb-3 card p-3"
            id="cardForm"
            v-if="
              caso.denu_fase3_observacion &&
              caso.denu_fase3_observacion.length > 0
            "
          >
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase3.observation`) }}
            </label>
            <textarea
              class="form-control"
              rows="5"
              v-model.trim="caso.denu_fase3_observacion"
              disabled
            />
          </div>
        </div>

        <div v-if="caso.denu_estado === 'DECISION'">
          <div class="mb-3 card p-3" v-if="cpeople">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase4.people`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesComuPeo"
              :key="item.defi_id"
              style="height: 22pt"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="caso.denu_comu_personas"
              disabled
              style="text-transform: uppercase"
            />
            <input
              v-model.trim="caso.denu_comu_personas_canal"
              type="text"
              class="form-control"
              :placeholder="$t(`mUser.ocases.phase4.channel`)"
              disabled
              style="margin-bottom: 0pt"
            />
          </div>
          <div class="mb-3 card p-3" v-if="cresponsible">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase4.responsible`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesComuRes"
              :key="item.defi_id"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="caso.denu_comu_responsable"
              disabled
              style="text-transform: uppercase"
            />
            <select
              class="form-select"
              v-model.trim="caso.denu_comu_responsable_canal"
              disabled
            >
              <option value="null" disabled>
                {{ $t("mUser.ocases.phase4.channel") }}
              </option>
              <option value="TELEFONO">
                {{ $t("mUser.ocases.phase4.phone") }}
              </option>
              <option value="CORREOELECTRONICO">
                {{ $t("mUser.ocases.phase4.email") }}
              </option>
              <option value="OTROS">
                {{ $t("mUser.ocases.phase4.otherchannel") }}
              </option>
            </select>
          </div>
          <div class="mb-3 card p-3" v-if="corganization">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase4.organization`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesComuOrg"
              :key="item.defi_id"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="caso.denu_comu_organizacion"
              disabled
              style="text-transform: uppercase"
            />
            <select
              class="form-select"
              v-model.trim="caso.denu_comu_organizacion_canal"
              disabled
            >
              <option value="null" disabled>
                {{ $t("mUser.ocases.phase4.channel") }}
              </option>
              <option value="TELEFONO">
                {{ $t("mUser.ocases.phase4.phone") }}
              </option>
              <option value="CORREOELECTRONICO">
                {{ $t("mUser.ocases.phase4.email") }}
              </option>
              <option value="OTROS">
                {{ $t("mUser.ocases.phase4.otherchannel") }}
              </option>
            </select>
          </div>
          <div class="mb-3 card p-3" v-if="ccompliant">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase4.compliant`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesComuDen"
              :key="item.defi_id"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="caso.denu_comu_denunciante"
              disabled
              style="text-transform: uppercase"
            />
            <select
              class="form-select"
              v-model.trim="caso.denu_comu_denunciante_canal"
              disabled
            >
              <option value="null" disabled>
                {{ $t("mUser.ocases.phase4.channel") }}
              </option>
              <option value="TELEFONO">
                {{ $t("mUser.ocases.phase4.phone") }}
              </option>
              <option value="CORREOELECTRONICO">
                {{ $t("mUser.ocases.phase4.email") }}
              </option>
              <option value="OTROS">
                {{ $t("mUser.ocases.phase4.otherchannel") }}
              </option>
            </select>
          </div>
          <div class="mb-3 card p-3" v-if="cadminpublica">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase4.adminpublic`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesComuApb"
              :key="item.defi_id"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="caso.denu_comu_adminpublica"
              disabled
              style="text-transform: uppercase"
            />
            <select
              class="form-select"
              v-model.trim="caso.denu_comu_adminpublica_canal"
              disabled
            >
              <option value="null" disabled>
                {{ $t("mUser.ocases.phase4.channel") }}
              </option>
              <option value="TELEFONO">
                {{ $t("mUser.ocases.phase4.phone") }}
              </option>
              <option value="CORREOELECTRONICO">
                {{ $t("mUser.ocases.phase4.email") }}
              </option>
              <option value="OTROS">
                {{ $t("mUser.ocases.phase4.otherchannel") }}
              </option>
            </select>
          </div>
          <div class="mb-3 card p-3" v-if="cother">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase4.other`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesComuOtros"
              :key="item.defi_id"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <input
              style="text-transform: uppercase"
              type="text"
              class="form-control"
              v-model="caso.denu_comu_otra_interesada"
              disabled
            />
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="caso.denu_comu_otras"
              disabled
              style="text-transform: uppercase"
            />
            <select
              class="form-select"
              v-model.trim="caso.denu_comu_otras_canal"
              disabled
            >
              <option value="null" disabled>
                {{ $t("mUser.ocases.phase4.channel") }}
              </option>
              <option value="TELEFONO">
                {{ $t("mUser.ocases.phase4.phone") }}
              </option>
              <option value="CORREOELECTRONICO">
                {{ $t("mUser.ocases.phase4.email") }}
              </option>
              <option value="OTROS">
                {{ $t("mUser.ocases.phase4.otherchannel") }}
              </option>
            </select>
          </div>
          <div class="mb-3 card p-3" v-if="cother1">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase4.other`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesComuOtros1"
              :key="item.defi_id"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <input
              style="text-transform: uppercase"
              type="text"
              class="form-control"
              v-model="caso.denu_comu_otra1_interesada"
              disabled
            />
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="caso.denu_comu_otras1"
              disabled
              style="text-transform: uppercase"
            />
            <select
              class="form-select"
              v-model.trim="caso.denu_comu_otras1_canal"
              disabled
            >
              <option value="null" disabled>
                {{ $t("mUser.ocases.phase4.channel") }}
              </option>
              <option value="TELEFONO">
                {{ $t("mUser.ocases.phase4.phone") }}
              </option>
              <option value="CORREOELECTRONICO">
                {{ $t("mUser.ocases.phase4.email") }}
              </option>
              <option value="OTROS">
                {{ $t("mUser.ocases.phase4.otherchannel") }}
              </option>
            </select>
          </div>
          <div class="mb-3 card p-3" v-if="cother2">
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase4.other`) }}
            </label>
            <div
              class="mb-3 margin-top: 5pt;"
              v-for="item in filesComuOtros2"
              :key="item.defi_id"
            >
              <span style="float: right"
                >{{ item.defi_nombre }}
                <a
                  class="btn button buttonEdit"
                  target="_blank"
                  :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                  style="margin-right: 5pt; margin-left: 5pt; margin-top: -5pt"
                  :style="{ backgroundColor: colorCompany }"
                >
                  <i class="fas fa-download"></i>
                </a>
              </span>
            </div>
            <input
              style="text-transform: uppercase"
              type="text"
              class="form-control"
              v-model="caso.denu_comu_otra2_interesada"
              disabled
            />
            <textarea
              class="form-control textboxphase3"
              rows="5"
              v-model.trim="caso.denu_comu_otras2"
              disabled
              style="text-transform: uppercase"
            />
            <select
              class="form-select"
              v-model.trim="caso.denu_comu_otras2_canal"
              disabled
            >
              <option value="null" disabled>
                {{ $t("mUser.ocases.phase4.channel") }}
              </option>
              <option value="TELEFONO">
                {{ $t("mUser.ocases.phase4.phone") }}
              </option>
              <option value="CORREOELECTRONICO">
                {{ $t("mUser.ocases.phase4.email") }}
              </option>
              <option value="OTROS">
                {{ $t("mUser.ocases.phase4.otherchannel") }}
              </option>
            </select>
          </div>
          <div
            class="card"
            id="cardForm"
            v-if="
              caso.denu_fase4_observacion &&
              caso.denu_fase4_observacion.length > 0
            "
          >
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase3.observation`) }}
            </label>
            <textarea
              class="form-control"
              rows="5"
              v-model.trim="caso.denu_fase4_observacion"
              disabled
            />
          </div>
          <div
            class="card"
            id="cardForm"
            v-if="
              caso.denu_resolucion_usuario &&
              caso.denu_resolucion_usuario.length > 0
            "
          >
            <label class="form-check-label">
              {{ $t(`mUser.ocases.phase4.resolucion`) }}
            </label>
            <textarea
              class="form-control"
              rows="5"
              v-model.trim="caso.denu_resolucion_usuario"
              disabled
            />
          </div>

        </div>

        <div v-if="caso.denu_estado === 'COMUNICACION'">
        <div v-if="InviUsu && InviUsu.length > 0">
          <h3>
            {{ $t(`mUser.ocases.InviUsu.title`) }}
          </h3>
          <div class="card" id="cardForm">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">{{ $t("mUser.ocases.InviUsu.name") }}</th>
                  <th scope="col">{{ $t("mUser.ocases.InviUsu.motivo") }}</th>
                </tr>
              </thead>
              <tbody style="text-align: justify">
                <tr v-for="item in InviUsu" :key="item.defi_id">
                  <td style="min-width: 100pt">
                    {{ item.usde_nombre }}
                  </td>
                  <td>{{ item.usde_texto }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

          <div v-if="filesUsu && filesUsu.length > 0">
            <h3>
              {{ $t(`mUser.ocases.filesUsu.title`) }}
            </h3>
            <div class="card" id="cardForm">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">{{ $t("mUser.ocases.files.date") }}</th>
                    <th scope="col">{{ $t("mUser.ocases.files.name") }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody style="text-align: justify">
                  <tr v-for="item in filesUsu" :key="item.defi_id">
                    <td style="min-width: 100pt">
                      {{ item.defi_fecha }}
                    </td>
                    <td>{{ item.defi_nombre }}</td>
                    <td>
                      <a
                        class="btn button buttonEdit"
                        target="_blank"
                        :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                        :style="{ backgroundColor: colorCompany }"
                      >
                        <i class="fas fa-download"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div v-if="messages && messages.length > 0">
            <h3>
              {{ $t(`mUser.ocases.messages.title`) }}
            </h3>
            <div class="card" id="cardForm">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">{{ $t("mUser.ocases.messages.date") }}</th>
                    <th scope="col">{{ $t("mUser.ocases.messages.text") }}</th>
                  </tr>
                </thead>
                <tbody style="text-align: justify">
                  <tr v-for="item in messages" :key="item.deme_id">
                    <td style="min-width: 100pt">
                      {{ item.deme_fecha }}
                    </td>
                    <td>{{ item.deme_texto }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!activeCase && cases && cases.length > 0">
           
          <div class="row">
            <div class="col">
            <input
              type="text"
              class="form-control"
              id="filtroCode"
              :placeholder="$t(`mUser.ocases.code`)"
              style="text-transform: uppercase"
              v-model="filtroCode"
              v-on:change="filtroDenuncias"
            />
            </div><div class="col">
            <input
              type="text"
              class="form-control"
              id="filtroTipo"
              :placeholder="$t(`mUser.ocases.type`)"
              style="text-transform: uppercase"
              v-model="filtroTipo"
              v-on:change="filtroDenuncias"
            /></div><div class="col">
            <input
              type="text"
              class="form-control"
              id="filtroDepa"
              :placeholder="$t(`statistics.relation`)"
              style="text-transform: uppercase"
              v-model="filtroDepa"
              v-on:change="filtroDenuncias"
            /></div><div class="col">
            <input
              type="text"
              class="form-control"
              id="filtroFase"
              :placeholder="$t(`mUser.ocases.phase`)"
              style="text-transform: uppercase"
              v-model="filtroFase"
              v-on:change="filtroDenuncias"
            /></div>
          </div>
                    <div class="row">
            <div class="col">
            <input
              type="text"
              class="form-control"
              id="filtroNatu"
              :placeholder="$t(`mUser.ocases.nature`)"
              style="text-transform: uppercase"
              v-model="filtroNatu"
              v-on:change="filtroDenuncias"
            />
            </div><div class="col">
            <input
              type="text"
              class="form-control"
              id="filtroPrior"
              :placeholder="$t(`statistics.priority`)"
              style="text-transform: uppercase"
              v-model="filtroPrior"
              v-on:change="filtroDenuncias"
            /></div>
          </div>

        
      </div>
      <table class="table" v-if="!activeCase && cases && cases.length > 0">
        <thead>
          <tr>
            <th scope="col">{{ $t("mUser.ocases.code") }}</th>
            <th scope="col">{{ $t("mUser.ocases.date") }}</th>
            <th scope="col">{{ $t("mUser.ocases.type") }}</th>
            <th scope="col">{{ $t("mUser.ocases.nature") }}</th>
            <th scope="col">{{ $t("statistics.relation") }}</th>
            <th scope="col">{{ $t("statistics.priority") }}</th>

            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in filterCases" :key="item.denu_id">
            <td>{{ item.denu_id.substring(0,10) }}</td>
            <td>{{ item.denu_fecha_alta }}</td>
            <td>
              <p v-if="item.denu_tipo_denuncia === 'CONSULTA'">
                {{ $t("mUser.ocases.consult") }}
              </p>
              <p v-if="item.denu_tipo_denuncia === 'NOMINAL'">
                {{ $t("mUser.ocases.nominal") }}
              </p>
              <p v-if="item.denu_tipo_denuncia === 'ANONIMO'">
                {{ $t("mUser.ocases.anonymous") }}
              </p>
            </td>
            <td>{{ item.denu_nature }}</td>
            <td>{{ item.denu_relacion }}</td>
            <td>{{ item.denu_prioridad }}</td>
            <td>
              <button
                class="btn button buttonEdit"
                @click="seeCase(item)"
                :style="{ backgroundColor: colorCompany }"
              >
                <i class="fas fa-search"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-if="!activeCase && cases && cases.length <= 0">
        {{ $t("errors.3") }}
      </p>
    </div>
  </div>

  <!-- Modal Invesitgaciones -->
  <div
    class="modal fade"
    id="investigacion"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("mUser.ocases.invPhase.title") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="px-4 py-3" v-if="phaseCase === 0">
            <div>
              <div>
                <div class="mb-3">
                  <label class="form-label">{{
                    $t(`mUser.ocases.invPhase.text`)
                  }}</label>
                  <textarea
                    v-model.trim="author.dein_texto"
                    class="form-control"
                    :placeholder="$t(`mUser.ocases.invPhase.text`)"
                    rows="9"
                    style="text-transform: uppercase"
                    disabled
                  />
                </div>
                <div
                  class="mb-3 margin-top: 5pt;"
                  v-for="item in filesDenuFich"
                  :key="item.defi_id"
                >
                  <span style="float: right; width: 100%; margin-bottom: 5pt"
                    >{{ item.defi_nombre }}
                    <a
                      class="btn button buttonEdit"
                      target="_blank"
                      :href="`${servidor}api/ver_archivo_denuncias.asp?denu_id=${item.defi_denuncia_cd}&defi_id=${item.defi_id}`"
                      style="margin-right: 5pt; margin-left: 5pt"
                      :style="{ backgroundColor: colorCompany }"
                    >
                      <i class="fas fa-download"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn-light btnClean"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.return") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuVer from "../../../components/MenuVer";
import moment from "moment";
import { mapMutations, mapState } from "vuex";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import axios from "axios";

export default {
  components: {
    MenuVer,
  },
  data() {
    moment.locale("es");
    return {
      cases: [],
      authors: [],
      author: {},
      files: [],
      natures: [],
      fonts: [],
      activeCase: false,
      caso: {},
      closephase2: {
        denu_final: "",
        denu_causas: "",
        denu_fase2_observaciones: "",
        denu_archivo_final_id: "",
      },
      isCrime: false,
      imgText: this.$t("forms.imgText"),
      phaseCase: null,
      emp_id: this.$route.params.idemp,
      departamentos: [],
      sedes: [],
      rela_name: "",
      result: "",
      loading: "",
      action: 0,
      fileInv: "",
      // Variables para fase 3
      people: false,
      compliance: false,
      organization: false,
      compliant: false,
      comunication: false,
      other: false,
      observation: false,
      // Variables para fase 4
      cpeople: false,
      ccompliance: false,
      corganization: false,
      ccompliant: false,
      ccomunication: false,
      cother: false,
      formClosePhase2Error: false,
      nocomunication: false,
      nodecision: false,
      messages: [],
      filesUsu: [],
      InviUsu: [],
      filesDenuFichFase3: [],
      filesDeciPeople: [],
      filesDeciCompl: [],
      filesDeciOrg: [],
      filesDeciOim: [],
      filesDeciComu: [],
      filesDeciOtros: [],
      filesDenuFichFase4: [],
      filesComuPeo: [],
      filesComuRes: [],
      filesComuOrg: [],
      filesComuDen: [],
      filesComuApb: [],
      filesComuOtros: [],
      filesComuOtros1: [],
      filesComuOtros2: [],
      // Variables para fase 4
      cpeople: false,
      cresponsible: false,
      corganization: false,
      ccompliant: false,
      cadminpublica: false,
      cother: false,
      cother1: false,
      cother2: false,
      bcpeople: "",
      bcresponsible: "",
      bcorganization: "",
      bccompliant: "",
      bcadminpublica: "",
      bcother: "",
      bcother1: "",
      bcother2: "",
      messages: [],
      filesUsu: [],
      InviUsu:[],
      filesDenuFichFase2: [],
      filesDenuFich: [],
      filtroCode: '',
      filtroTipo: '',
      filtroDepa: '',
      filtroFase: '',
      filtroNatu: '',
      filtroDepa2: '',
      filtroSede: '',
      filtroPrior: '',      
      rute: "",
      filterCases: []
    };
  },
  created() {
    this.getColorType();
    this.changeBreadCrumb(20);
    this.bigDrop();
    this.getDenuncias();
  },
  computed: {
    ...mapState(["id_usu", "usu_rol", "colorCompany", "servidor"]),
  },
  methods: {
    ...mapMutations([
      "changeBreadCrumb",
      "bigDrop",
      "changeColorCompanyGreen",
      "changeColorCompanyBlue",
      "changeColorCompanyRed",
      "changeColorCompanyOrange",
      "changeColorCompany4",
      "changeColorCompany5",
      "changeColorCompany6",
      "changeColorCompany7",
      "changeColorCompany8",
      "changeColorCompany9",
      "changeColorCompany10",
      "changeColorCompany11",
      "changeColorCompany12",
      "changeColorCompany13",
      "changeColorCompany14",
      "changeColorCompany15",
      "changeColorCompany16",
    ]),
    seeCase(item) {
      this.activeCase = true;
      this.result = "";
      this.getDenuncia(item.denu_id);
      this.seeMessages(item.denu_id);
      this.getFilesUsu(item.denu_id);
      this.getInviUsu(item.denu_id);
      this.filesDenuFichFase2 = [];
    },
    async getFilesDenuFich(type) {
      let id = "";

      if (type === "INVES") {
        id = this.author.dein_id;
      } else if (type === "FASE2") {
        id = this.caso.denu_id;
      }

      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=listarArchivos&id=${id}&type=${type}`
        );
        if (res.data) {
          if (type === "INVES") {
            this.filesDenuFich = res.data.data;
          } else if (type === "FASE2") {
            this.filesDenuFichFase2 = res.data.data;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async seeMessages(id) {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=denunciante&ac=listarMensajes&id=${id}`
        );
        if (res.data) {
          this.messages = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getFilesUsu(id) {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=denunciante&ac=listadoArchivo&id=${id}`
        );
        if (res.data) {
          this.filesUsu = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getInviUsu(id) {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=denunciante&ac=listadoInvi&id=${id}`
        );
        if (res.data) {
          this.InviUsu = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getDenuncias() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=historial&id=${this.emp_id}`
        );
        if (res.data) {
          this.cases = res.data.data;
          this.filterCases = this.cases;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async filtroDenuncias() {
        var fTipo;
        var fFase;
        fFase = this.filtroFase.toString().toUpperCase();
        if (fFase.indexOf("AN")>=0) {
          fFase = "CREADA";
          }

        if (fFase.indexOf("DE")>=0) fFase = "ANALISIS";
        if (fFase.indexOf("CO")>=0) fFase = "DECISION";

        fTipo = this.filtroTipo.toString().toUpperCase().replace("Ó","O");
        fTipo = fTipo.replace("ANONIMA","ANONIMO");
        this.filterCases = this.cases.filter((c) => c.denu_id.toString().toUpperCase().includes(this.filtroCode.toString().toUpperCase())).filter((c) => c.denu_relacion.toString().toUpperCase().includes(this.filtroDepa.toString().toUpperCase())).filter((c) => c.denu_tipo_denuncia.toString().toUpperCase().includes(fTipo)).filter((c) => c.denu_nature.toString().toUpperCase().includes(this.filtroNatu.toString().toUpperCase())).filter((c) => c.denu_prioridad.toString().toUpperCase().includes(this.filtroPrior.toString().toUpperCase()));
    },
    async getDenuncia(id) {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=denuncia&id=${id}`
        );
        if (res.data && res.data.data) {
          this.caso = res.data.data[0];
          this.rute = this.caso.ruta;
          this.getDatasCases();
        }
      } catch (error) {
        console.error(error);
      }
    },
    getDatasCases() {
      if (this.caso.denu_estado === "FINALIZADA") {
        this.caso.denu_estado = "CREADA";
      }
      if (this.caso.denu_estado === "CREADA") {
        this.getFilesDenuFich("FASE2");
        this.changeBreadCrumb(28);
        if (this.caso.denu_tipo_delito) {
          this.isCrime = true;
        } else {
          this.isCrime = false;
        }
        if (this.caso.denu_tipo_denuncia !== "CONSULTA") {
          this.getDepartamentos();
          this.getSedes();
        }
        if (this.caso.denu_naturaleza_cd === null) {
          this.caso.denu_naturaleza_cd = 0;
        }
        this.getRelation(this.caso.denu_relacion_cd);
        this.getNatures();
        this.getInv(this.caso.denu_id);
        this.getFiles(this.caso.denu_id);
      } else if (this.caso.denu_estado === "ANALISIS") {
        this.changeBreadCrumb(29);
        if (
          this.caso.denu_deci_personas &&
          this.caso.denu_deci_personas.length > 0
        ) {
          this.people = true;
        } else {
          this.caso.denu_deci_personas = "";
          this.people = false;
        }

        if (
          this.caso.denu_deci_compliance &&
          this.caso.denu_deci_compliance.length > 0
        ) {
          this.compliance = true;
        } else {
          this.caso.denu_deci_compliance = "";
          this.compliance = false;
        }

        if (
          this.caso.denu_deci_organizacion &&
          this.caso.denu_deci_organizacion.length > 0
        ) {
          this.organization = true;
        } else {
          this.caso.denu_deci_organizacion = "";
          this.organization = false;
        }

        if (
          this.caso.denu_deci_otras_implicadas &&
          this.caso.denu_deci_otras_implicadas.length > 0
        ) {
          this.compliant = true;
        } else {
          this.caso.denu_deci_otras_implicadas = "";
          this.compliant = false;
        }

        if (
          this.caso.denu_deci_comunicacion &&
          this.caso.denu_deci_comunicacion.length > 0
        ) {
          this.comunication = true;
        } else {
          this.caso.denu_deci_comunicacion = "";
          this.comunication = false;
        }

        if (this.caso.denu_deci_otras && this.caso.denu_deci_otras.length > 0) {
          this.other = true;
        } else {
          this.caso.denu_deci_otras = "";
          this.other = false;
        }

        if (
          !this.people &&
          !this.compliance &&
          !this.organization &&
          !this.compliant &&
          !this.comunication &&
          !this.other
        ) {
          this.nodecision = true;
        } else {
          this.nodecision = false;
        }
        this.getFilesFase3();
      } else if (this.caso.denu_estado === "DECISION") {
        this.changeBreadCrumb(30);
        this.changeBreadCrumb(27);
        this.phase4 = true;
        if (
          this.caso.denu_comu_personas &&
          this.caso.denu_comu_personas.length > 0
        ) {
          this.cpeople = true;
        } else {
          this.cpeople = false;
          this.caso.denu_comu_personas = "";
        }

        if (
          this.caso.denu_comu_responsable &&
          this.caso.denu_comu_responsable.length > 0
        ) {
          this.cresponsible = true;
        } else {
          this.cresponsible = false;
          this.caso.denu_comu_responsable = "";
        }

        if (
          this.caso.denu_comu_organizacion &&
          this.caso.denu_comu_organizacion.length > 0
        ) {
          this.corganization = true;
        } else {
          this.corganization = false;
          this.caso.denu_comu_organizacion = "";
        }

        if (
          this.caso.denu_comu_denunciante &&
          this.caso.denu_comu_denunciante.length > 0
        ) {
          this.ccompliant = true;
        } else {
          this.ccompliant = false;
          this.caso.denu_comu_denunciante = "";
        }

        if (
          this.caso.denu_comu_adminpublica &&
          this.caso.denu_comu_adminpublica.length > 0
        ) {
          this.cadminpublica = true;
        } else {
          this.cadminpublica = false;
          this.caso.denu_comu_adminpublica = "";
        }

        if (this.caso.denu_comu_otras && this.caso.denu_comu_otras.length > 0) {
          this.cother = true;
        } else {
          this.cother = false;
          this.caso.denu_comu_otras = "";
        }

        if (
          this.caso.denu_comu_otras1 &&
          this.caso.denu_comu_otras1.length > 0
        ) {
          this.cother1 = true;
        } else {
          this.cother1 = false;
          this.caso.denu_comu_otras1 = "";
        }

        if (
          this.caso.denu_comu_otras2 &&
          this.caso.denu_comu_otras2.length > 0
        ) {
          this.cother2 = true;
        } else {
          this.cother2 = false;
          this.caso.denu_comu_otras2 = "";
        }
        this.getFilesFase4();
      }
    },
    async getDepartamentos() {
      try {
        const empresa = {
          emp_id: this.emp_id,
        };
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=departamentos`,
          empresa
        );
        if (res.data) {
          this.departamentos = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getSedes() {
      try {
        const empresa = {
          emp_id: this.emp_id,
        };
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=sedes`,
          empresa
        );
        if (res.data) {
          this.sedes = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getRelation(id) {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=rela&ac=listadoid&id=${id}`
        );
        if (res.data.data) {
          this.rela_name = res.data.data[0].rela_nombre;
        } else if (res.data.RESULT) {
          console.log("No data");
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getNatures() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op.asp?tp=natura&ac=listado`
        );
        if (res.data) {
          this.natures = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getInv(id) {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=investigacion&ac=listado&id=${id}`
        );
        if (res.data) {
          this.authors = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getFiles(id) {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=listarArchivosDenuncia&id=${id}`
        );
        if (res.data) {
          this.files = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async seeMessages(id) {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=denunciante&ac=listarMensajes&id=${id}`
        );
        if (res.data) {
          this.messages = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getInviUsu(id) {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=denunciante&ac=listadoInvi&id=${id}`
        );
        if (res.data) {
          this.InviUsu = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getFilesUsu(id) {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=denunciante&ac=listadoArchivo&id=${id}`
        );
        if (res.data) {
          this.filesUsu = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    editInv(item) {
      this.action = 1;
      this.author = item;
      this.result = "";
      this.phaseCase = 0;
      this.getFilesDenuFich("INVES");
    },
    closeCase() {
      this.action = 2;
      this.phaseCase = 1;
      this.closephase2.denu_final = "";
      this.closephase2.denu_causas = "";
      this.closephase2.denu_fase2_observaciones = "";
      this.closephase2.denu_archivo_final_id = "";
      if (this.$refs.archivoFinal && this.$refs.archivoFinal.getFile(0)) {
        this.$refs.archivoFinal.removeFile(this.$refs.archivoFinal.getFile(0));
      }
    },
    async exportCase() {
//      const doc = new jsPDF("p", "pt", "A4");

      const res = await axios.post(
        `${this.servidor}api/export.asp?id=${this.caso.denu_id}`
      );

//      doc.html(res.data, {
//        x: 10,
//        y: 10,
//        callback: function (doc) {
//          doc.save();
//        },
//      });
var mapForm = document.createElement("form");
    mapForm.target = "_blank";    
    mapForm.method = "POST";
    mapForm.action = `${this.servidor}api/exportacion.asp`;

    var mapInput = document.createElement("input");
    mapInput.type = "text";
    mapInput.name = "id";
    mapInput.value = `${this.caso.denu_id}`;
    mapForm.appendChild(mapInput);

    document.body.appendChild(mapForm);

    mapForm.submit();
      this.tableToExport = false;
    },
    verArchivo(item) {
      this.logo = `${this.servidor}api/ver_archivo.asp?denu_id=${this.caso.denu_id}&dear_id=${item.dear_id}`;
    },
    seePhaseAnalysis(direction) {
      if (direction === 0) {
        this.caso.denu_estado = "CREADA";
        this.getDatasCases();
      } else if (direction === 1) {
        this.caso.denu_estado = "ANALISIS";
        this.getDatasCases();
      } else if (direction === 2) {
        this.caso.denu_estado = "DECISION";
        this.getDatasCases();
      } else if (direction === 3) {
        this.caso.denu_estado = "COMUNICACION";
        this.getFilesUsu(this.caso.denu_id);
        this.getInviUsu(this.caso.denu_id);
        this.seeMessages(this.caso.denu_id);
      }
    },
    closeCardPhase() {
      this.caso = {};
      this.activeCase = false;
    },
    async getColorType() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_emp.asp?ac=color&id=${this.emp_id}`
        );
        const color = res.data.emp_color;
        if (color === 0) {
          this.changeColorCompanyOrange();
        } else if (color === 1) {
          this.changeColorCompanyBlue();
        } else if (color === 2) {
          this.changeColorCompanyGreen();
        } else if (color === 3) {
          this.changeColorCompanyRed();
        } else if (color === 4) {
          this.changeColorCompany4();
        } else if (color === 5) {
          this.changeColorCompany5();
        } else if (color === 6) {
          this.changeColorCompany6();
        } else if (color === 7) {
          this.changeColorCompany7();
        }else if (color === 8) {
          this.changeColorCompany8();
        }else if (color === 9) {
          this.changeColorCompany9();
        }else if (color === 10) {
          this.changeColorCompany10();
        }else if (color === 11) {
          this.changeColorCompany11();
        }else if (color === 12) {
          this.changeColorCompany12();
        }else if (color === 13) {
          this.changeColorCompany13();
        }else if (color === 14) {
          this.changeColorCompany14();
        }else if (color === 15) {
          this.changeColorCompany15();
        }else if (color === 16) {
          this.changeColorCompany16();
        }
      } catch (error) {
        console.error(error);
      }
    },
    filterDates(fecha) {
      return moment(fecha).format("MM/DD/YYYY, h:mm:ss");
    },
    async getFilesFase3() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=listarArchivosFase3&id=${this.caso.denu_id}`
        );
        if (res.data) {
          this.filesDenuFichFase3 = res.data.data;

          if (this.filesDenuFichFase3) {
            this.filesDeciPeople = this.filesDenuFichFase3.filter(
              (file) => file.defi_tipo === "DEPER"
            );
          }

          if (this.filesDenuFichFase3) {
            this.filesDeciCompl = this.filesDenuFichFase3.filter(
              (file) => file.defi_tipo === "DECOM"
            );
          }

          if (this.filesDenuFichFase3) {
            this.filesDeciOrg = this.filesDenuFichFase3.filter(
              (file) => file.defi_tipo === "DEORG"
            );
          }

          if (this.filesDenuFichFase3) {
            this.filesDeciOim = this.filesDenuFichFase3.filter(
              (file) => file.defi_tipo === "DEOIM"
            );
          }

          if (this.filesDenuFichFase3) {
            this.filesDeciComu = this.filesDenuFichFase3.filter(
              (file) => file.defi_tipo === "DECUM"
            );
          }

          if (this.filesDenuFichFase3) {
            this.filesDeciOtros = this.filesDenuFichFase3.filter(
              (file) => file.defi_tipo === "DEOTR"
            );
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getFilesFase4() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_denuncia.asp?tp=datos&ac=listarArchivosFase4&id=${this.caso.denu_id}`
        );

        if (res.data) {
          this.filesDenuFichFase4 = res.data.data;

          if (this.filesDenuFichFase4) {
            this.filesComuPeo = this.filesDenuFichFase4.filter(
              (file) => file.defi_tipo === "COPER"
            );
          }

          if (this.filesDenuFichFase4) {
            this.filesComuRes = this.filesDenuFichFase4.filter(
              (file) => file.defi_tipo === "CORES"
            );
          }

          if (this.filesDenuFichFase4) {
            this.filesComuOrg = this.filesDenuFichFase4.filter(
              (file) => file.defi_tipo === "COORG"
            );
          }

          if (this.filesDenuFichFase4) {
            this.filesComuDen = this.filesDenuFichFase4.filter(
              (file) => file.defi_tipo === "CODEN"
            );
          }

          if (this.filesDenuFichFase4) {
            this.filesComuApb = this.filesDenuFichFase4.filter(
              (file) => file.defi_tipo === "COAPB"
            );
          }

          if (this.filesDenuFichFase4) {
            this.filesComuOtros = this.filesDenuFichFase4.filter(
              (file) => file.defi_tipo === "COOTR"
            );
          }

          if (this.filesDenuFichFase4) {
            this.filesComuOtros1 = this.filesDenuFichFase4.filter(
              (file) => file.defi_tipo === "COOT1"
            );
          }

          if (this.filesDenuFichFase4) {
            this.filesComuOtros2 = this.filesDenuFichFase4.filter(
              (file) => file.defi_tipo === "COOT2"
            );
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

select {
  color: #898f96;
  height: 35pt;
  margin-bottom: 40px;
}

#cardForm {
  margin-top: 40px;
  padding: 25pt;
  display: block;
  margin-bottom: 40px;
}

.close-case {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
  width: 100%;
  border-radius: 20pt;
}

.close-form {
  float: right;
  font-size: 17pt;
  margin-top: -28pt;
}

.export-case {
  margin-top: -30pt;
  margin-right: 50pt;
}

.see-phase2 {
  margin-top: -30pt;
}

.btn-header {
  float: none;
  background-color: cornflowerblue;
  margin-top: 20pt;
  margin-bottom: 20pt;
  margin-right: 5pt;
}

.textboxphase3 {
  margin-bottom: 5pt;
}

.active {
  background-color: #666666;
}

.btnClean {
  background-color: #a4a9a8;
  padding: 10px;
  width: 100%;
  border-radius: 50px;
  color: #fff;
}

.nodeci {
  margin-right: 221pt;
}

.sideci {
  margin-right: 322pt;
}

.header-button {
  width: 100pt;
}

/* button:focus {
  outline: none;
  box-shadow: 0px 0px 0px 3px rgb(255 128 0 / 20%);
} */
</style>