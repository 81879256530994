<template>
  <div class="container mt-4">

    <div class="row">
      <div class="col center-col">
      </div>
      <div class="col center-col">
        <div class="dropdown p-4" id="card">
          <div class="row">
            <div class="col"></div>
            <div class="col center-col">
              <img :src="logo" />
            </div>
            <div class="col"></div>
          </div>
          <form class="px-4 py-3" @submit.prevent="restore">
            <div class="mb-3">
              <i class="fas fa-envelope icon-email"></i>
              <input
                type="email"
                class="form-control"
                id="exampleDropdownFormEmail1"
                v-model.trim="email"
                :placeholder="$t(`login.email`)"
              />
              <span
                v-if="formError"
                class="text-danger"
                style="font-size: 12px"
                >{{ $t("errors.errorEmail") }}</span
              >
            </div>
            <button type="submit" class="btn button" data-bs-toggle="modal" :style="{ backgroundColor: colorCompany }"
            data-bs-target="#resultadoModal">
              {{ $t("actions.restore") }}
            </button>
          </form>
        </div>
      </div>
      <div class="col center-col">
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div
    class="modal fade"
    id="resultadoModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("login.restore") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" v-if="resp === ''">
          {{ $t("login.finalMesssage") }}
        </div>
        <div class="modal-body" v-else-if="resp === 'error'">
          {{ $t("login.restoreFail") }}
        </div>
        <div class="modal-footer">
          <button
            @click="cerrar()"
            type="button"
            class="btn btn-response"
            data-bs-dismiss="modal"
          >
            {{ $t("actions.close") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import { mapMutations, mapState } from 'vuex';

export default {
  name: "Login",
  data() {
    return {
      email: "",
      resp: "error",
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      formError: false,
      emp_id: this.$route.params.idemp
    };
  },
  created() {
    this.formError = false;
    this.littleDrop();
    this.changeBreadCrumb(18);
  },
  computed: {
    ...mapState(["colorCompany","servidor","logo"])
  },
  methods: {
    ...mapMutations(['changeBreadCrumb','littleDrop']),
    async restore() {
      if (!this.isEmailValid()) {
        this.formError = true;
        return;
      }
      const restore = {
        usu_empresa_cd: this.emp_id,
        usu_email: this.email,
      };
      const res = await axios.post(
        `${this.servidor}api/ident_usuario.asp?ac=cambiar`,
        restore
      );
      if (res.data.RESULT.toString() === '0') {
        this.resp = '';
      }
    },
    cerrar() {
      this.resp = "1";
      this.$router.push(`/${this.emp_id}/user/login`);
    },
    isEmailValid() {
      return this.email == ""
        ? ""
        : this.reg.test(this.email)
        ? true
        : false;
    },
  },
};
</script>


<style scoped>
#card {
  width: 500px;
  border-radius: 30px;
  background-color: white;
  margin-top: 185px;
}

.field-icon {
  float: right;
  margin-top: -69px;
  position: relative;
  z-index: 2;
  left: -11px;
  cursor: pointer;
}

.icon-email {
  float: left;
  top: 31px;
  position: relative;
  left: 11px;
}

.button {
  padding: 10px;
  width: 100%;
  border-radius: 50px;
  color: #fff;
  background-color: #62e4d0;
}

.label {
  font-size: 10pt;
  color: #a4a9a8;
  margin-top: 19pt;
  float: right;
}

input {
  height: 35pt;
  text-indent: 50px;
}

.center-col {
  display: flex;
  justify-content: center;
}

.img-fondo {
  margin-top: 72pt;
  width: 100%;
  height: 75%;
}

.btn-response {
  background-color: #a4a9a8;
  border-radius: 20pt;
  color: white;
  width: 20%;
}
</style>