<template>
  <div class="container mt-4">
    <div class="row" style="margin-top: 70pt">
      <div class="col center-col">
        <div class="dropdown p-4" id="card">
       
          <form
            class="px-4 py-3"
            @submit.prevent="signIn"
            style="margin-bottom: 37px"
          >
            <div class="mb-3">
              <i class="fas fa-user icon-name"></i>
              <input
                style="text-transform: uppercase"
                type="text"
                class="form-control"
                v-model.trim="company.emp_usuario"
                :placeholder="$t(`login.user`)"
              />
            </div>
            <div class="mb-3">
              <i class="fas fa-key icon-password"></i>
              <input
                v-if="visPas"
                type="password"
                class="form-control"
                id="exampleDropdownFormPassword1"
                v-model.trim="company.emp_clave"
                :placeholder="$t(`login.password`)"
              />
              <input
                v-else
                type="text"
                class="form-control"
                id="epassword"
                v-model.trim="company.emp_clave"
              />
              <i
                v-if="!visPas"
                @click="visPas = true"
                class="fas fa-eye-slash field-icon"
              ></i>
              <i
                v-else
                @click="visPas = false"
                class="fas fa-eye field-icon"
              ></i>
            </div>
            <button type="submit" class="btn button">
              {{ $t("actions.singin") }}
            </button>
          </form>
          <span v-if="error == 1" class="text-danger" style="font-size: 15px">{{
            $t("login.errorUsu")
          }}</span>
          <span
            v-if="error === 2"
            class="text-danger"
            style="font-size: 15px"
            >{{ $t("login.errorBlock") }}</span
          >
          <!-- <router-link class="dropdown-item label" to="/admin/restore" style="width: auto">{{
            $t("login.forgot")
          }}</router-link> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations, mapState } from 'vuex';

export default {
  name: "Login",
  data() {
    return {
      company: {
        emp_usuario: "",
        emp_clave: ""
      },
      visPas: true,
      error: 0,
    };
  },
  created () {
    this.littleDrop();
    this.changeBreadCrumb(10);
  },
  computed: {
    ...mapState(['servidor']),
    block() {
      if (this.company.emp_clave.length > 5) {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...mapMutations(["littleDrop", "changeBreadCrumb", "changeIdEmp", "changeLogo","changeColorCompanyGreen","changeColorCompanyBlue","changeColorCompanyRed","changeColorCompanyOrange","changeColorCompany4","changeColorCompany5","changeColorCompany6","changeColorCompany7","changeColorCompany8","changeColorCompany9","changeColorCompany10","changeColorCompany11","changeColorCompany12","changeColorCompany13","changeColorCompany14","changeColorCompany15","changeColorCompany16"]),
    async signIn() {
      if (this.company.emp_usuario === "") {
        this.error = 1;
        return;
      }
      if (this.company.emp_clave === "") {
        this.error = 1;
        return;
      }
      const company = {
        emp_usuario: this.company.emp_usuario.toUpperCase(),
        emp_clave: this.company.emp_clave
      };
      try {
        const res = await axios.post(
          `${this.servidor}api/ident_empresa.asp?ac=identificar`,
          company
        );
        this.result = res.data.RESULT;
        this.changeIdEmp(res.data.emp_id);
        this.verImagen(res.data.emp_id);
        const color = res.data.color_type;
        if (color === 0) {
          this.changeColorCompanyOrange();
        } else if (color === 1) {
          this.changeColorCompanyBlue();
        } else if (color === 2) {
          this.changeColorCompanyGreen();
        } else if (color === 3) {
          this.changeColorCompanyRed();
        } else if (color === 4) {
          this.changeColorCompany4();
        } else if (color === 5) {
          this.changeColorCompany5();
        }else if (color === 6) {
          this.changeColorCompany6();
        }else if (color === 7) {
          this.changeColorCompany7();
        }else if (color === 8) {
          this.changeColorCompany8();
        }else if (color === 9) {
          this.changeColorCompany9();
        }else if (color === 10) {
          this.changeColorCompany10();
        }else if (color === 11) {
          this.changeColorCompany11();
        }else if (color === 12) {
          this.changeColorCompany12();
        }else if (color === 13) {
          this.changeColorCompany13();
        }else if (color === 14) {
          this.changeColorCompany14();
        }else if (color === 15) {
          this.changeColorCompany15();
        }else if (color === 16) {
          this.changeColorCompany16();
        }
        // 1 incorrecta usuario o clave
        // 0 todo bien
        // 2 usuario bloqueado
        if (this.result === "0") {
          this.$router.push("/admin/user");
        } else if (this.result === "1") {
          this.error = 1;
        } else if (this.result === "3") {
          this.$router.push("/admin/configuration");
        }
        else {
          this.error = 2;
        }
        this.cleanForm();
      } catch (error) {
        console.error(error);
      }
      // VueCookieNext.setCookie("louscd", true, { expire: Infinity });
    },
    cleanForm() {
      this.company = {
        emp_usuario: "",
        emp_clave: "",
      };
    },
    verImagen(id_emp) {
      const logo = `${this.servidor}api/ver_logotipo.asp?emp_id=${id_emp}`;
      this.changeLogo(logo);      
    },
  },
};
</script>

<style scoped>
#card {
  width: 500px;
  border-radius: 30px;
  background-color: white;
  margin-top: 70px;
}

.field-icon {
  float: right;
  margin-top: -69px;
  position: relative;
  z-index: 2;
  left: -11px;
  cursor: pointer;
}

.icon-name {
  float: left;
  top: 31px;
  position: relative;
  left: 11px;
}

.icon-password {
  float: left;
  top: 31px;
  position: relative;
  left: 11px;
}

.button {
  padding: 10px;
  width: 100%;
  border-radius: 50px;
  color: #fff;
  background-color: #666666;
}

.label {
  font-size: 10pt;
  color: #a4a9a8;
  margin-top: 19pt;
  float: right;
}

input {
  height: 35pt;
  text-indent: 50px;
}

.center-col {
  display: flex;
  justify-content: center;
}

.img-fondo {
  margin-top: 72pt;
  width: 100%;
  height: 75%;
  position: fixed;
  margin-left: -205pt;
}

.img-logo {
  padding: 5pt;
  width: 225pt;
}


</style>